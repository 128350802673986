<template>
  <div>
    <!-- <pre>
      {{ favorites }}
    </pre> -->
    <div v-if="loading" class="d-flex justify-center">
      <circularLoader />
    </div>
    <div>
      <div v-if="favorites.length == 0 && !loading" class="text-center">
        <img src="@/assets/images/pana.svg" aspect-ratio="1.5" class="my-5" />
        <p class="font-16">{{ $t("You have not added any favorite games") }}</p>
      </div>
      <v-row v-else no-gutters style="background: black !important">
        <v-col
          v-for="(favorite, index) in favorites"
          :key="index"
          :cols="favorites.length > 3 ? 6 : 12"
          :sm="favorites.length > 1 ? 6 : 12"
        >
          <Video
            :favorite="favorite"
            @reRender="getFavorites"
            :index="index"
            :count="favorites.length"
            :key="favorites.length + index"
            :remove="() => editFavorite(favorite)"
          />
          <div v-if="display" class="new">{{ name }}</div>
          <div v-if="displayWebsite" class="new">
            {{ website }}
          </div>
        </v-col>
      </v-row>
      <div class="toolBar d-flex flex-column">
        <v-icon center class="mt-1 mb-3" size="24" @click="back" color="#60baef"
          >mdi-backburger</v-icon
        >
        <v-icon
          center
          color="white"
          size="24"
          @click="toggle()"
          v-if="!isFullscreen && !isIos && !loading && favorites.length != 0"
        >
          mdi-fullscreen
        </v-icon>
        <v-icon
          center
          color="white"
          size="24"
          @click="toggle()"
          v-if="isFullscreen && !isMobile && !isIos && !loading && favorites.length != 0"
        >
          mdi-fullscreen-exit
        </v-icon>
      </div>
    </div>

    <div class="live-strip" :class="{ selected: showStrip, dismiss: !showStrip }">
      <div
        v-for="(game, i) in liveGames"
        class="fav-live-game-wrap"
        :key="i"
        @click="editFavorite(game)"
      >
        <div class="fav-live-game">{{ game.name }}</div>
      </div>
    </div>
    <div class="strip-hamburger" @click="showStrip = !showStrip">
      <v-icon> {{ showStrip ? "mdi-close" : "mdi-menu" }} </v-icon>
    </div>
  </div>
</template>
<script>
import favoriteService from "@/services/favoriteService";
import Header from "@/components/Header.vue";
import Video from "@/components/Video";
import CircularLoader from "../components/circularLoader.vue";
import * as SocketioService from "@/services/socketioService.js";
import gameService from "@/services/gameService";
export default {
  data() {
    return {
      favorites: [],
      zIndex: 100,
      loading: false,
      isIos: false,
      isMobile: false,
      isFullscreen: false,
      display: false,
      usernameTurn: true,
      displayWebsite: false,
      websiteTurn: false,
      name: this.$store.state.user.username,
      website: window.location.hostname,
      interval1: null,
      interval2: null,
      liveGames: [],
      showStrip: false,
    };
  },
  components: {
    Video,
    Header,
    CircularLoader,
  },
  async mounted() {
    this.checkIos();
    this.checkMobile();
    this.getFavorites();
    await this.getLiveGames();
    console.log(
      '%cFavorites.vue line:113 "Mounted -rendered"',
      "color: #007acc;",
      "Mounted -rendered"
    );
  },
  methods: {
    checkIos() {
      if (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      ) {
        this.isIos = true;
      }
    },
    checkMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        this.isMobile = true;
      }
    },
    async joinSockets() {
      await SocketioService.socket.joinRooms({
        roomNames: ["1sportsstream" + this.$store.state.user.userId, "streamRoom"],
      });
      await SocketioService.socket.streamRoom({
        roomName: "1sportsstream" + this.$store.state.user.userId,
        accessToken: localStorage.accessToken,
      });
    },
    showUserNameService() {
      SocketioService.eventBus.$on("show-username", async () => {
        this.display = true;
        setTimeout(
          function () {
            this.display = false;
          }.bind(this),
          process.env.VUE_APP_ENV_SHOWUSERNAME_TIMING
        );
      });
    },
    showWebsiteService() {
      SocketioService.eventBus.$on("show-website", async () => {
        this.displayWebsite = true;
        setTimeout(
          function () {
            this.displayWebsite = false;
          }.bind(this),
          process.env.VUE_APP_ENV_SHOWUSERNAME_TIMING
        );
      });
    },
    socketReconnection() {
      SocketioService.eventBus.$on("reconnected", async () => {
        console.log("reconnected");
        await SocketioService.socket.joinRoom({ roomName: "streamRoom" });
      });
    },
    displayUsernameAndWebsite() {
      this.interval1 = setInterval(() => {
        if (this.usernameTurn) {
          this.display = true;
          setTimeout(
            function () {
              this.display = false;
            }.bind(this),
            process.env.VUE_APP_ENV_SHOWUSERNAME_TIMING
          );
        }
        this.usernameTurn = !this.usernameTurn;
      }, 600000);
      this.interval2 = setInterval(() => {
        if (this.websiteTurn) {
          this.displayWebsite = true;
          setTimeout(
            function () {
              this.displayWebsite = false;
            }.bind(this),
            process.env.VUE_APP_ENV_SHOWUSERNAME_TIMING
          );
        }
        this.websiteTurn = !this.websiteTurn;
      }, 600000);
    },
    async getFavorites() {
      this.loading = true;
      try {
        const result = await favoriteService.getFavoritesForUser();
        if (result.status === 200) {
          const decrypted = this.$CryptoJS.AES.decrypt(
            result.data.data,
            process.env.VUE_APP_ENV_CRYPTO_KEY
          ).toString(this.$CryptoJS.enc.Utf8);
          this.favorites = JSON.parse(decrypted);
          await this.joinSockets();
          this.showUserNameService();
          this.showWebsiteService();
          this.socketReconnection();
          this.displayUsernameAndWebsite();
          this.manageFullscreen();
        }
      } catch (error) {
        if (
          document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.msFullscreenElement
        ) {
          this.exitFullscreen1();
        }
        if (
          error.response &&
          error.response.status == 404 &&
          error.response.data == "Not Subscribed"
        ) {
          this.$store.commit("snackbar/setSnackbar", {
            content: this.$t("Please Subscribe to see Favorites"),
            color: "error ",
            isVisible: true,
          });
          this.$router.push("/subscription");
        }
        console.log(error);
      }
      this.loading = false;
    },
    async back() {
      await this.$dialog
        .confirm({
          text: this.$t("Do you really want to exit?"),
          actions: {
            false: this.$t("popups.no"),
            true: this.$t("popups.yes"),
          },
        })
        .then((res) => {
          if (res == true) {
            this.$router.push("/");
          }
        });
    },
    toggle() {
      if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
      ) {
        this.exitFullscreen1();
      } else {
        this.requestFullscreen1();
      }
    },
    manageFullscreen() {
      setTimeout(async () => {
        if (
          document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.msFullscreenElement
        ) {
          this.isFullscreen = true;
        } else {
          this.isFullscreen = false;
          if (this.isMobile && !this.isIos) {
            await this.$dialog
              .confirm({
                text: "Enter Full-screen?",
                actions: {
                  false: this.$t("popups.no"),
                  true: this.$t("popups.yes"),
                },
              })
              .then((res) => {
                if (res == true) {
                  this.requestFullscreen1();
                }
              });
          }
        }
        window.addEventListener("fullscreenchange", (event) => {
          if (
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.msFullscreenElement
          ) {
            this.isFullscreen = true;
          } else {
            this.isFullscreen = false;
          }
        });
      }, 2000);
    },
    requestFullscreen1() {
      var elem = document.getElementById("app");
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitEnterFullscreen) {
        elem.webkitEnterFullscreen();
      }
      // screen.orientation.lock("landscape-primary");
      if (screen.orientation && screen.orientation.lock) {
        screen.orientation
          .lock("landscape-primary")
          .then(() => {
            // Lock successful
          })
          .catch((error) => {
            console.error("Error locking screen orientation:", error);
          });
      } else {
        console.warn("Screen orientation lock is not supported on this device.");
      }
    },
    exitFullscreen1() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozRequestFullScreen) {
        document.mozExitFullScreen();
      }
      screen.orientation.unlock();
    },
    async getLiveGames() {
      try {
        const data = {
          user: this.$store.state.user.userId,
          // type: this.type == 0 ? "" : this.tags[this.type],
        };
        const result = await gameService.getLiveGamesLight(data);
        if (result.status == 200 && result?.data?.isError === false) {
          this.liveGames = result.data.data.data;
        } else {
          console.log(result?.data);
        }
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    async editFavorite(gamesData) {
      const data = {
        gameId: gamesData._id,
        userId: this.$store.state.user.userId,
        status: !gamesData.favorite ? "enabled" : "disabled",
      };
      try {
        const result = await favoriteService.editFavorite(data);
        if (result.status == 200 && result?.data?.isError === false) {
          if (result.data.data == this.$t("You can't add more than 4 favorites")) {
            this.$store.commit("snackbar/setSnackbar", {
              content: result.data.data,
              color: "error",
              isVisible: true,
            });
          } else {
            this.$emit("onEdit");
            this.$store.commit("snackbar/setSnackbar", {
              content:
                result.data.data.status == "enabled"
                  ? this.$t("Added to Favorite")
                  : this.$t("Removed from Favorite"),
              color: "success",
              isVisible: true,
            });

            this.getLiveGames();
            this.getFavorites();
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isIos) {
      next();
      window.location = window.location;
    } else {
      next();
    }
  },
};
</script>
<style lang="scss" scoped>
.live-strip {
  position: fixed;
  width: 100vw;
  bottom: 0;
  display: flex;
  padding-left: 2rem;
  gap: 2rem;
  overflow-x: scroll;
  background: black;
  font-weight: 600;
  z-index: 1000;
  left: -100vw;
}
.fav-live-game {
  position: relative;
  padding-left: 30px;
  border-radius: 5px;
  padding: 0.5rem 1rem 0.5rem 30px;
  white-space: nowrap;
  cursor: pointer;
  background: transparent linear-gradient(90deg, #73bdf1, #0c3261) !important;
  border-radius: 50px;
}
.fav-live-game::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #0000001a;
  background: red;
  animation: pulse-animation 2s infinite;
  position: absolute;
  left: 15px;
  top: 40%;
}
.fav-live-game-wrap {
  padding-left: 20px;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 0, 0, 0.689);
  }
  100% {
    box-shadow: 0 0 0 6px rgba(255, 162, 162, 0);
  }
}

.toolBar {
  position: absolute;
  bottom: 70px;
  left: 10px;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.52);
  display: inline;
}
.strip-hamburger {
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 1000;

  .v-icon {
    background: rgba(0, 0, 0, 0.52);
    cursor: pointer;
    color: #60baef !important;
  }
}
.black-transparent-patch {
  background-color: rgba(0, 0, 0, 0.8);
}

.selected {
  animation: slide-in 0.25s forwards;
  -webkit-animation: slide-in 0.25s forwards;
}

.backButtonIcon {
  font-size: 1.5rem !important;
}

.dismiss {
  animation: slide-out 0.25s forwards;
  -webkit-animation: slide-out 0.25s forwards;
}
@keyframes slide-in {
  0% {
    left: -100vw;
  }
  100% {
    left: 0;
  }
}

@-webkit-keyframes slide-in {
  0% {
    left: -100vw;
  }
  100% {
    left: 0;
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}
</style>
