<template>
  <div class="">
    <div class="d-flex justify-center" v-if="loading">
      <circularLoader />
    </div>
    <div v-else class="header-section">
      <div color="#2c2a2b" fixed class="drawer-icon">
        <router-link v-if="isMobile" to="/" class="d-flex justify-end">
          <img src="@/assets/images/logo.svg" alt="Logo" height="35px" />
        </router-link>
        <div
          class="font-22 text-center heading"
          style="text-transform: uppercase"
          v-if="!isMobile"
        >
          {{ title }}
        </div>

        <div class="nav-btns">
          <v-btn class="refresh-btn mr-2" @click="routeLink('/favorites')">
            <v-icon size="large">mdi-check-circle</v-icon>
            <span class="icons-title" v-if="!isMobile"> favourites </span>
          </v-btn>
          <v-btn class="refresh-btn" @click="reload()">
            <v-icon size="large" class="">mdi-refresh</v-icon>
            <span class="icons-title" v-if="!isMobile"> Refresh </span>
          </v-btn>
          <div v-if="isMobile" @click.stop="drawer = !drawer" class="hamburger">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M3 17H21M3 12H21M3 7H21"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
      <v-navigation-drawer
        fixed
        top
        :left="!isMobile"
        :right="isMobile"
        :permanent="!isMobile"
        width="80%"
        style="max-width: 400px; background: #60baef"
        class="header-drawer-content"
        :class="{ hideNav: isMobile }"
        v-model="drawer"
      >
        <div class="d-flex my-6 justify-space-between">
          <div class="ml-6 d-flex">
            <img src="@/assets/images/profile.svg" alt="account" />
            <div>
              <div class="font-26 ml-4 pt-1">{{ name }}</div>
              <h4
                v-if="planDetails && planDetails.status"
                class="ml-2"
                :class="{
                  'black-txt': planDetails.status == 'active',
                  'red-txt':
                    planDetails.status == 'expired' ||
                    planDetails.status == 'cancelled/refunded' ||
                    planDetails.status == 'paused',
                }"
              >
                &#9679;
                {{ planDetails.status == "active" ? "Active" : "Expired" }}
                <span v-if="planDetails.status == 'active'"
                  >({{ planDetails.diff }} days remaining)</span
                >
              </h4>
            </div>
          </div>
        </div>
        <v-divider class="divider-color"></v-divider>
        <v-list
          nav
          dense
          v-for="(menu, index) in MainMenus"
          :key="index"
          class="pa-0 ml-4 mt-6"
        >
          <template v-if="index == 3">
            <div class="d-flex">
              <div class="ml-4 mt-1">
                <v-img :src="menu.image" alt="edit" width="24px" />
              </div>
              <switch-locale class="ml-7" style="width: 180px" />
            </div>
          </template>
          <v-list-item-group v-else>
            <!-- <v-list-item-group v-else v-model="group" > -->
            <v-list-item :to="menu.link">
              <div class="ml-2">
                <v-img :src="menu.image" alt="edit" width="24px" />
              </div>
              <v-list-item-title>
                <span class="font-18 ml-7">
                  {{ menu.title }}
                </span>
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <div class="footer-nav mt-5 mx-5">
          <v-card class="mx-auto my-5 footer-card mx-2" max-width="344" outlined>
            <v-list-item three-line :style="{ display: installBtn }">
              <v-list-item-content>
                <v-list-item-title class="mb-5 d-flex justify-center">
                  {{ $t("sideBar.downloadOurApp") }}
                </v-list-item-title>
                <v-row>
                  <v-col cols="12" sm="12" class="d-flex justify-center white-txt">
                    <v-btn class="" outlined color="white" download @click="installer()">
                      <v-icon large color="white"> mdi-android</v-icon>
                      {{ $t("sideBar.installApp") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </div>
        <div class="close-nav" @click.stop="drawer = !drawer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
          >
            <path
              d="M10.5801 19.4187L19.4201 10.5813M10.5801 10.5813L19.4201 19.4187"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>
        </div>

        <div class="bottom"></div>
      </v-navigation-drawer>
    </div>
  </div>
</template>

<script>
import changePassword from "@/assets/images/change.svg";
import language from "@/assets/images/language.svg";
import install from "@/assets/images/install.svg";
import logout from "@/assets/images/logout.svg";
import Home from "@/assets/images/home.svg";
import Change from "@/assets/images/change.svg";
import Fav from "@/assets/images/fav.svg";
import circularLoader from "@/components/circularLoader.vue";
import SwitchLocale from "./switchLocale.vue";
import NoSleep from "nosleep.js";
import gameService from "@/services/gameService";

export default {
  props: ["title", "planDetails"],
  components: {
    circularLoader,
    SwitchLocale,
  },
  data() {
    return {
      name: this.$store.state.user.username,
      drawer: true,
      check: false,
      checkHomePage: false,
      group: null,
      MainMenus: [
        {
          image: Home,
          title: this.$t("Home"),
          link: "/",
        },
        {
          image: Change,
          title: this.$t("sideBar.changePassword"),
          link: "/resetpassword",
        },
        {
          image: Fav,
          title: this.$t("Favourites"),
          link: "/Favorites",
        },
        {
          image: language,
          title: this.$t("sideBar.languages"),
          link: "/",
        },
        {
          image: install,
          title: this.$t("sideBar.installApp") + " (Iphone)",
          link: "/installPwaIos",
        },
        {
          image: logout,
          title: this.$t("sideBar.logout"),
          link: "/login",
        },
      ],
      installBtn: "none",
      installer: undefined,
      loading: false,
      isMobile: window.innerWidth <= 768,
    };
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  async created() {
    this.loading = true;
    let installPrompt;

    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      installPrompt = e;
      this.installBtn = "block";
    });

    this.installer = () => {
      this.installBtn = "none";
      installPrompt.prompt();
      installPrompt.userChoice.then((result) => {
        if (result.outcome === "accepted") {
          console.log("Install accepted!");
        } else {
          console.log("Install denied!");
        }
      });
    };
    this.loading = false;
    if (this.isMobile) {
      this.drawer = false;
    }
  },
  methods: {
    updateIsMobile() {
      this.isMobile = window.innerWidth <= 768;
      console.log("%cHeader.vue line:200 isMobile", "color: #007acc;", this.isMobile);
    },
    async routeLink(link) {
      if (link == "/favorites") {
        if (this.$nosleep) this.$nosleep.disable();
        this.$nosleep = new NoSleep();
        this.$nosleep.enable();
        try {
          const result = await gameService.authenticateSubscribedUser();
          if (result.status == 200 && result.data.isVerified == true) {
            this.$router.push(link);
          }
        } catch (error) {
          console.log(error);
          if (error.response.status == 404 && error.response.data == "Not Subscribed") {
            this.$store.commit("snackbar/setSnackbar", {
              content: this.$t("Please Subscribe to Watch Match"),
              color: "error ",
              isVisible: true,
            });
            this.$router.push("/subscription");
          }
          console.log(error);
        }
      } else {
        this.$router.push(link);
      }
    },
    reload() {
      location.reload(true);
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.updateIsMobile);
  },
  mounted() {
    if (this.$router.currentRoute.path === "/") {
      this.checkHomePage = true;
    }
    window.addEventListener("resize", this.updateIsMobile);
  },
};
</script>
<style lang="scss">
.v-input-field {
  .v-text-field__details {
    display: none !important;
  }
  .v-input__slot {
    margin-bottom: 0px;
    font-weight: 500;
  }
  .v-label {
    font-size: 19px;
    font-weight: 500;
  }
  .v-input {
    font-size: 19px;
    font-weight: 500;
  }
  .v-application .mt-4 {
    margin-top: 0px !important;
  }
  .v-input__control {
    min-height: 0px !important;
  }
  .v-icon.notranslate.mdi.mdi-menu-down.theme--dark {
    display: none;
  }
}

header {
  box-shadow: none !important;
  padding: 2rem;
  width: 80%;
  margin-left: auto;
  @media only screen and (max-width: 768px) {
    width: 100% !important;
  }
}
.heading {
  color: #fff;
  font-family: Unbounded;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.24px;
}
.header-section {
  margin-bottom: 6px;
}
.drawer-icon {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}
.divider-color {
  color: #60baef !important;
}
.hamburger {
  width: 24px;
  display: flex;
  justify-content: flex-end;
}
.footer-card {
  border-color: white !important;
  background-color: #60baef !important;
  color: white !important;
}
.ios {
  min-width: 141.92px !important;
}
.refresh-btn {
  border-radius: 10px;
  border: 1px solid #9a9a9a;
  background: rgba(255, 255, 255, 0.2) !important;
}
aside {
  width: 20% !important;
}
@media only screen and (max-width: 768px) {
  aside {
    width: 80% !important;
  }
}
.nav-btns {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.close-nav {
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 3%;
  right: 5%;
  display: none;
}
.icons-title {
  margin-left: 0.125rem;
}
@media only screen and (max-width: 768px) {
  .header-section {
    background-color: #3b99d1;
  }
  .refresh-btn {
    border: 1px solid white;
    padding: 0 8px !important;
    min-width: 32px !important;
  }
  .close-nav {
    display: flex;
  }
}
</style>
