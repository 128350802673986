import Vue from "vue";
import _ from "lodash";
Vue.filter("capitalize", function (value) {
  if (!value) {
    return "";
  }
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});
Vue.filter("formatDate", function (value) {
  return new Date(value)
    .toString("en-US", {
      timeZone: "Asia/Kolkata",
    })
    .slice(4, 15);
});
Vue.filter("formatDate2", function (value) {
  return new Date(value.slice(0, -1))
    .toString("en-US", {
      timeZone: "Asia/Kolkata",
    })
    .slice(4, 15);
});
Vue.filter("formatDateTime", function (value) {
  return new Date(value)
    .toString("en-US", {
      timeZone: "Asia/Kolkata",
    })
    .slice(4, 21);
});
Vue.filter("formatMonth", function (value) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const d = new Date(value);
  return monthNames[d.getMonth()];
});
Vue.filter("formatNumber", function (value) {
  return value.toLocaleString();
});
Vue.filter("teamNames", function (value) {
  if (value && _.size(_.words(value)) == 2) {
    return _.words(value)[0] + " " + _.words(value)[1].slice(0, 3);
  } else if (value && _.size(_.words(value)) > 2)
    return _.words(value)[0] + " " + _.words(value)[1];
  else return value;
});
Vue.filter("formatOdds", function (value) {
  if (value) {
    return parseFloat(value).toFixed(2).replace(/\.00$/, "");
  }
  return value
});
