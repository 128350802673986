<template>
  <v-app>
    <v-main v-if="loading">
      <router-view />
      <Snackbar />

      <v-dialog
        v-if="dialog && $router.currentRoute.path == '/'"
        v-model="dialog"
        class="dialog"
        max-width="400px"
      >
        <div class="install-modal">
          <div class="d-flex justify-end">
            <v-icon size="18px" class="cancel" @click="closeModal()"> mdi-close </v-icon>
          </div>
          <div class="d-flex justify-center mt-3 mb-6">
            <v-btn @click="installer()" color="white" style="" outlined
              ><v-icon color="white">mdi-download</v-icon
              ><span class="install-text">&nbsp; Install Rpro5 App?</span></v-btn
            >
          </div>
        </div>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import Snackbar from "@/components/Snackbar.vue";
import Footer from "@/components/Footer.vue";
import * as SocketioService from "./services/socketioService.js";
// import * as playSocketService from "./services/playSocketService.js";
import NoSleep from "nosleep.js";

export default {
  name: "App",
  components: { Snackbar, Footer },
  data: () => ({
    registration: null,
    refreshing: false,
    loading: false,
    dialog: false,
  }),
  async created() {
    this.loading = false;
    const isBrowser = matchMedia("(display-mode: browser)").matches;
    if (!isBrowser) {
      window.resizeTo(912, 720);
    }
    SocketioService.socket.setupSocketConnection();
    // playSocketService.socket.setupSocketConnection();
    SocketioService.eventBus.$on("connection-left", (data) => {
      if (String(data) !== localStorage.accessToken) {
        this.$router.push({
          name: "Login",
          params: {
            screenExceed: true,
          },
        });
      }
    });
    SocketioService.eventBus.$on("connection-blocked", () => {
      this.$router.push({
        path: "/",
      });
    });
    let roomNames = ["all"];
    if (this.$store.state.user.isLoggedIn) {
      roomNames.push("1sports" + this.$store.state.user.userId);
      roomNames.push("1sportsstream" + this.$store.state.user.userId);
      await SocketioService.socket.streamRoom({
        roomName: "1sportsstream" + this.$store.state.user.userId,
        accessToken: localStorage.accessToken,
      });
    }
    await SocketioService.socket.joinRooms({
      roomNames: roomNames,
    });
    SocketioService.eventBus.$on("reconnected", async () => {
      console.log("reconnected");
      let roomNames = ["all"];
      if (this.$store.state.user.isLoggedIn) {
        roomNames.push("1sports" + this.$store.state.user.userId);
        roomNames.push("1sportsstream" + this.$store.state.user.userId);
        await SocketioService.socket.streamRoom({
          roomName: "1sportsstream" + this.$store.state.user.userId,
          accessToken: localStorage.accessToken,
        });
      }
      await SocketioService.socket.joinRooms({
        roomNames: roomNames,
      });
    });
    if (!this.$store.state.language.language) {
      if (navigator.language.substring(0, 2) == "en") {
        this.$store.commit("language/setLanguage", "en");
      } else if (navigator.language.substring(0, 2) == "hi") {
        this.$store.commit("language/setLanguage", "hi");
      } else if (navigator.language.substring(0, 2) == "gu") {
        this.$store.commit("language/setLanguage", "gu");
      } else if (navigator.language.substring(0, 2) == "te") {
        this.$store.commit("language/setLanguage", "te");
      } else if (navigator.language.substring(0, 2) == "ta") {
        this.$store.commit("language/setLanguage", "ta");
      } else if (navigator.language.substring(0, 2) == "kn") {
        this.$store.commit("language/setLanguage", "kn");
      }
    }
    this.$i18n.locale = this.$store.state.language.language;
    window.addEventListener("click", () => {
      if (this.$router.currentRoute.path.includes("/livestream/")) {
        if (this.$nosleep) this.$nosleep.disable();
        this.$nosleep = new NoSleep();
        this.$nosleep.enable();
      }
    });
    document.addEventListener("serviceWorkerUpdateEvent", this.appUpdateUI, {
      once: true,
    });
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });
    this.loading = true;
    let installPrompt;
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      installPrompt = e;
      if (!this.$store.state.dialog.updateDialog) {
        this.$store.commit("dialog/setAppInstallDialog", true);
        this.dialog = true;
      }
      this.installer = () => {
        this.dialog = false;
        installPrompt.prompt();
        installPrompt.userChoice.then((result) => {
          if (result.outcome === "accepted") {
            this.$store.commit("dialog/setAppInstallDialog", false);
            console.log("Install accepted!");
          } else {
            console.log("Install denied!");
          }
        });
      };
    });
  },
  methods: {
    async appUpdateUI(e) {
      this.$store.commit("dialog/setUpdateDialog", true);
      this.registration = e.detail;
      await this.$dialog
        .confirm({
          text: "Update available, please refresh.",
          actions: {
            true: "Refresh",
          },
        })
        .then((res) => {
          if (res == true) {
            if (this.registration || this.registration.waiting) {
              this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
            }
          } else {
            this.$store.commit("dialog/setUpdateDialog", false);
          }
        });
    },
    closeModal() {
      this.dialog = false;
      this.$store.commit("dialog/setAppInstallDialog", false);
    },
  },
  watch: {
    "$store.state.dialog.updateDialog": {
      async handler() {
        if (this.$store.state.dialog.updateDialog && this.dialog) {
          this.dialog = false;
        }
      },
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/Scss/_main.scss";
.install-modal {
  background: #60baef;
  color: white;
  padding: 1rem;
  width: 100%;
}
.cancel {
  background: white;
  border-radius: 1rem;
  color: #60baef !important;
}
.install-text {
  font-weight: 700;
}
</style>
