<template>
  <div
    class="oddsMessageBox d-flex flex-column justify-center"
    :class="{ oddsMessageBoxF: favorites }"
  >
    <div v-if="message" class="oddsMessage" :class="{ oddsMessageF: favorites }">
      {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["message", "favorites"],
};
</script>

<style lang="scss" scoped>
.oddsMessageBox {
  min-width: 110px;
  min-height: 30px !important;
  color: black;
  text-align: center;
  background: rgb(224, 224, 224, 0.8) !important;
  margin-left: 5px;
}
.oddsMessage {
  font-size: 0.75rem !important;
}
@media only screen and (min-width: 1000px) {
  .oddsMessageBox {
    min-width: 130px;
    height: 45px !important;
  }
  .oddsMessage {
    font-size: 1rem !important;
  }
}
@media only screen and (max-width: 700px) {
  .oddsMessageBox {
    min-width: 80px;
    height: 25px !important;
  }
  .oddsMessage {
    font-size: 0.625rem !important;
  }
}
.oddsMessageBoxF {
  min-width: 60px;
  min-height: 20px !important;
  color: black;
  text-align: center;
  background: rgb(224, 224, 224, 0.8) !important;
  margin-left: 2px;
}
.oddsMessageF {
  font-size: 0.625rem !important;
}
@media only screen and (min-width: 1000px) {
  .oddsMessageBoxF {
    min-width: 80px;
    height: 30px !important;
  }
  .oddsMessageF {
    font-size: 10px !important;
  }
}
@media only screen and (max-width: 700px) {
  .oddsMessageBoxF {
    min-width: 40px;
    height: 15px !important;
  }
  .oddsMessageF {
    font-size: 6px !important;
  }
}
</style>
