import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

const userModule = {
  namespaced: true,
  state: () => ({
    isLoggedIn: false,
    userId: null,
    username: "User",
    language: "en-US",
    updateDialog: false,
    planDetails: {},
  }),
  mutations: {
    setUser(state, payload) {
      state.isLoggedIn = payload;
    },
    setUserId(state, payload) {
      state.userId = payload;
    },
    setUserName(state, payload) {
      state.username = payload;
    },
    setUserLanguage(state, payload) {
      state.language = payload;
    },
    setPlanDetails(state, payload) {
      state.planDetails = payload;
    },
  },
  actions: {},
  getters: {},
};

const snackbarModule = {
  namespaced: true,
  state: () => ({
    snackbar: {
      content: "",
      color: "",
      icon: "",
      isVisible: false,
    },
  }),
  mutations: {
    setSnackbar(state, payload) {
      state.snackbar = payload;
      setTimeout(() => {
        state.snackbar.isVisible = false;
      }, 2000);
    },
    closeSnackbar(state) {
      state.snackbar.isVisible = false;
    },
  },
  getters: {
    getSnackbar: (state) => {
      return state.snackbar;
    },
  },
};
const languageModule = {
  namespaced: true,
  state: () => ({
    language: "",
  }),
  mutations: {
    setLanguage(state, payload) {
      state.language = payload;
    },
  },
  actions: {},
  getters: {},
};
const dialogModule = {
  namespaced: true,
  state: () => ({
    updateDialog: false,
    appInstallDialog: undefined,
  }),
  mutations: {
    setUpdateDialog(state, payload) {
      state.updateDialog = payload;
    },
    setAppInstallDialog(state, payload) {
      state.appInstallDialog = payload;
    },
  },
  actions: {},
  getters: {},
};
export default new Vuex.Store({
  modules: {
    user: userModule,
    snackbar: snackbarModule,
    language: languageModule,
    dialog: dialogModule,
  },
  plugins: [
    createPersistedState({
      paths: ["user", "language"],
    }),
  ],
});
