import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store";
import "@/helpers/filters";
import VueCryptojs from "vue-cryptojs";
Vue.use(VueCryptojs);
import "./registerServiceWorker";
import VuetifyDialog from "vuetify-dialog";
import "vuetify-dialog/dist/vuetify-dialog.css";
import i18n from "./i18n";
// import * as Sentry from "@sentry/vue";
Vue.prototype.$nosleep = null;
Vue.use(VuetifyDialog, {
  context: {
    vuetify,
  },
});
Vue.config.productionTip = false;
import * as Flashphoner from "@flashphoner/websdk";
Vue.use(Flashphoner);
Vue.mixin({
  methods: {
    getLanguage: function () {
      if (this.$store.state.user.isLoggedIn) {
        return this.$store.state.user.language;
      }
      return this.$store.state.language.language;
    },
  },
});
// Sentry.init({
//   Vue,
//   dsn: process.env.VUE_APP_ENV_SENTRY_DSN,
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: [process.env.VUE_APP_ENV_SENTRY_URL],
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions
//   // Session Replay
//   replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
