var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"oddsBox d-flex flex-column justify-center",class:{
    greybg: !_vm.odds && !_vm.volume,
    oddsBoxF: _vm.favorites,
    oddsBoxFancy: _vm.fancy,
    oddsFavHeight: _vm.count > 1 && _vm.isFav,
    odds4Height: _vm.count > 2 && _vm.isFav,
    bookHeight: _vm.isbook && _vm.count > 2 && _vm.isFav,
  },style:({ background: _vm.color })},[(_vm.odds)?_c('div',{staticClass:"odds",class:{ oddsF: _vm.favorites, oddsSmallFont: _vm.count > 1 }},[_vm._v(" "+_vm._s(_vm.fancy ? `${_vm.odds}` : `${_vm.numderOdd}`)+" ")]):_vm._e(),(_vm.volume)?_c('div',{staticClass:"volume",class:{ volumeF: _vm.favorites, volumeSmallFont: _vm.count > 1 }},[_vm._v(" "+_vm._s(_vm.volume)+" ")]):_vm._e(),(!_vm.odds && !_vm.volume)?_c('div',[_vm._v("-")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }