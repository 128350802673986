<template>
  <div v-if="images.length > 0" class="banner-wrapper">
    <img :src="currentImage.url" class="ads-banner" />
  </div>
</template>

<script>
export default {
  name: "ads-banner",
  props: ["images"],
  data() {
    return {
      currentIndex: 0,
      intervalId: null,
      abortController: null, // Initialize abortController
    };
  },
  computed: {
    currentImage() {
      if (this.images.length > 0) {
        // Check if currentIndex is within the range of the images array
        if (this.currentIndex >= 0 && this.currentIndex < this.images.length) {
          return this.images[this.currentIndex];
        } else {
          // If currentIndex exceeds the range, loop through by setting index to 0
          this.currentIndex = 0;
          return this.images[this.currentIndex];
        }
      }
      return ""; // Return an empty string if no images are available
    },
  },
  methods: {},
  created() {
    this.intervalId = setInterval(() => {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    }, 10000);
  },
  mounted() {
    console.log("prop", this.images);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
    if (this.abortController) {
      this.abortController.abort(); // Abort the fetch request if it's still ongoing
    }
  },
};
</script>

<style lang="scss" scoped>
.banner-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ads-banner {
  object-fit: contain;
  width: 100%;
  height: 100%;
  margin: auto;
  margin-left: auto;
  z-index: 999;
}
</style>
