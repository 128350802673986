<template>
  <div>
    <v-container v-if="link">
      <v-card style="border: 1px solid #7FC7D9">
        <v-card-title class="justify-center">Click below button to Download App</v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-btn color="#7FC7D9" rounded outlined :href="link" target="_blank"
            >Download App</v-btn
          >
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import versionService from "@/services/versionService.js";
export default {
  data() {
    return {
      link: undefined,
    };
  },
  created() {
    this.getDownloadLink();
  },
  methods: {
    async getDownloadLink() {
      try {
        const result = await versionService.getDownloadLink();
        if (result.status == 200) {
          this.link = result.data.url;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
