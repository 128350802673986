<template>
  <div class="text-center bg">
    <div class="banner">
      <img
        class="desktop-cover"
        v-if="$vuetify.breakpoint.smAndDown"
        src="@/assets/images/R5.svg"
      />
      <img class="mobile-cover" v-else src="@/assets/images/R5.svg" />
    </div>
    <v-card class="card-section">
      <form @submit.prevent="login">
        <h3 class="login-heading">LOGIN</h3>
        <v-divider class="my-4"></v-divider>
        <div class="input-field">
          <div>
            <label
              :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : ' mt-12'"
              class="d-flex"
              >Username <span class="text-red ml-1"> *</span></label
            >
            <div class="mt-3 d-flex">
              <v-text-field
                :label="
                  !$v.userName.minLength && $v.userName.$error
                    ? userNameErrors
                    : 'Username *'
                "
                v-model="userName"
                :error-messages="userNameErrors"
                outlined
                required
                @input="$v.userName.$touch()"
                @blur="$v.userName.$touch()"
              >
              </v-text-field>
            </div>
          </div>
          <div>
            <label
              class="d-flex"
              :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : ' mt-8'"
              >Password <span class="text-red ml-1"> *</span></label
            >
            <div class="mt-3 password"></div>
            <v-text-field
              v-model="password"
              outlined
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              @click:append="show1 = !show1"
              :label="
                !$v.password.minLength && $v.password.$error
                  ? passwordErrors
                  : 'Password *'
              "
              :error-messages="passwordErrors"
              required
              @input="$v.password.$touch()"
              @blur="$v.password.$touch()"
            >
            </v-text-field>
          </div>
          <div class="mt-8 text-center">
            <v-btn type="submit" class="font-16 login-btn py-7">{{
              $t("login.login")
            }}</v-btn>
          </div>
        </div>
      </form>
    </v-card>
    <v-dialog v-model="dialog" persistent max-width="450">
      <v-card style="border: 1px solid white !important">
        <v-card-title class="text-h5 gold-bg black-txt">
          {{ $t("popups.loginOnAnotherDeviceDetected") }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="white" @click="dialog = false" outlined light>
            {{ $t("popups.okay") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import userService from "@/services/userService";
import * as SocketioService from "@/services/socketioService.js";
import VueJwtDecode from "vue-jwt-decode";
export default {
  name: "Login",
  props: ["screenExceed"],
  data() {
    return {
      userName: "",
      password: "",
      show1: false,
      dialog: false,
      isIos: false,
    };
  },
  mixins: [validationMixin],
  validations: {
    userName: { required, minLength: minLength(3) },
    password: {
      required,
      minLength: minLength(4),
    },
  },
  computed: {
    userNameErrors() {
      const errors = [];
      if (!this.$v.userName.$dirty) return errors;
      !this.$v.userName.minLength &&
        errors.push("Username must be at least 3 characters long");
      !this.$v.userName.required && errors.push("Username is required.");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.minLength &&
        errors.push("Password must be at least 4 characters long");
      !this.$v.password.required && errors.push("Password is required");
      return errors;
    },
  },
  async created() {
    if (this.$store.state.user.userId) {
      let roomNames = ["all"];
      roomNames.push("1sports" + this.$store.state.user.userId);
      roomNames.push("1sportsstream" + this.$store.state.user.userId);
      roomNames.push("streamRoom");
      await SocketioService.socket.leaveRooms(roomNames);
    }
    localStorage.removeItem("accessToken");
    this.$store.commit("user/setUser", false);
    this.$store.commit("user/setUserId", null);
    this.$store.commit("user/setUserLanguage", "en");
    this.checkIos();
    if (this.screenExceed) {
      this.dialog = true;
    }
  },
  methods: {
    checkIos() {
      if (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      ) {
        this.isIos = true;
      }
    },
    async login() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      try {
        const data = {
          username: this.userName,
          password: this.password,
          iPhone: this.isIos,
        };
        const result = await userService.login(data);
        if (result.status == 200 && result?.data?.isError === false) {
          this.$store.commit("snackbar/setSnackbar", {
            content: this.$t("Login Successfull"),
            color: "success",
            isVisible: true,
          });
          localStorage.accessToken = result.data.data;
          const decoded = VueJwtDecode.decode(localStorage.accessToken);
          if (decoded.deviceType == "Android-Web" && this.isIos) {
            this.$store.commit("snackbar/setSnackbar", {
              content:
                "Please login from android/web or contact admin to get Iphone account",
              color: "error",
              isVisible: true,
            });
            return;
          }
          this.$store.commit("user/setUser", true);
          this.$store.commit("user/setUserId", decoded.userId);
          this.$store.commit("user/setUserName", decoded.username);
          if (decoded && decoded.planDetails)
            this.$store.commit("user/setPlanDetails", decoded.planDetails);
          if (decoded.language) {
            this.$store.commit("language/setLanguage", decoded.language);
            this.$i18n.locale = this.$store.state.language.language;
          }
          await SocketioService.socket.joinRooms({
            roomNames: [
              "1sports" + decoded.userId,
              "1sportsstream" + this.$store.state.user.userId,
            ],
          });
          await SocketioService.socket.streamRoom({
            roomName: "1sportsstream" + this.$store.state.user.userId,
            accessToken: localStorage.accessToken,
          });
          this.$router.push("/");
        } else {
          this.$store.commit("snackbar/setSnackbar", {
            content: result?.data?.message || "Invalid username or password",
            color: "error ",
            isVisible: true,
          })
        }
      } catch (e) {
        console.log(e);
        this.$store.commit("snackbar/setSnackbar", {
          content: "Invalid username or password",
          color: "error ",
          isVisible: true,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.card-section {
  position: relative;
  width: 55% !important;
  background-color: transparent !important;
  // border: .5px solid #f9f9f9!important;
  opacity: 0.9;
  width: 85vw;
  height: 100vh;
  // max-width: 500px !important;
  border-radius: 0.5rem !important;
  padding: 2rem;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.3));
  padding-top: 7%;
  display: flex;

  @media only screen and (max-width: 600px) {
    width: 100vw !important;
    overflow-x: hidden;
  }
}
.input-field {
  .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border-color: white !important;
  }
  .theme--light.v-label {
    color: white !important;
  }
  .theme--light.v-input input,
  .theme--light.v-input textarea {
    color: white !important;
  }
  .v-application .primary--text {
    color: white !important;
    caret-color: white !important;
  }
  .v-label {
    font-size: 14px !important;
  }
}
.bg {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  overflow: hidden;
  // background: url("https://wallpaperaccess.com/full/3818759.jpg") no-repeat
  // center center;
  background-size: cover;
  background-color: #2c2a2b;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    height: auto;
    overflow-x: hidden;
  }
}

.banner {
  width: 45%;
  overflow: hidden;
  background-size: contain;
  height: 100vh;
  overflow: hidden;
  display: grid;
  place-items: center;

  img {
    width: 50% !important;
    background-size: cover;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    height: auto;
    margin-top: 2rem;
    img {
      width: 80% !important;
      background-size: cover;
    }
  }
}

.v-input__slot {
  border-radius: 0.5rem;
  background: rgba(255, 255, 255, 0.2) !important;
  border-radius: 10px;
  // border: 1px solid #9A9A9A;
  background: rgba(255, 255, 255, 0.2);
  filter: blur(0px);
}
.password .mdi:before {
  color: white;
}

.login-heading {
  font-weight: 600;
  font-size: 3rem;
  text-align: left;
  margin-bottom: 1.5rem;
  font-weight: 700;
  letter-spacing: 5.76px;
  color: #fff;
  line-height: normal;
  font-family: Unbounded;

  @media only screen and (max-width: 600px) {
    font-size: 2rem; // Adjust the font size for screens <= 400px
  }
}
.login-btn {
  width: 100%;
  background: #3b99d1 !important;
  font-weight: 700 !important;
  font-size: 2rem;
  border-radius: 10px;
}

.v-icon.v-icon.v-icon--link {
  font-size: 1rem;
}

form {
  width: 70%;
  margin-left: 10%;

  @media only screen and (max-width: 600px) {
    width: 100vw !important;
    margin-left: 0%;
  }
}

.text-red {
  color: red;
}

.mobile-cover {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.v-card__actions {
  background: #60baef;
}
</style>
