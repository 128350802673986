import axios from "axios";
/* eslint-disable */
const loginUrl = process.env.VUE_APP_ENV_1SPORTS_API_URL;

export default {
  login(data) {
    return axios.post(`${loginUrl}/user/login`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  resetPassword(data) {
    return axios.post(`${loginUrl}/user/resetPasswordOfUserByUser`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.accessToken,
      },
    });
  },
  getUserDetails() {
    return axios.get(`${loginUrl}/user/getUserDetails`, {
      headers: {
        Authorization: localStorage.accessToken,
        "Content-Type": "application/json",
      },
    });
  },
  updateUserLanguage(data) {
    return axios.post(`${loginUrl}/user/updateUserLanguage`, data, {
      headers: {
        Authorization: localStorage.accessToken,
        "Content-Type": "application/json",
      },
    }); 
  }
};
