<template>
  <div>
    <v-card class="footer-card">
      <v-footer v-bind="localAttrs" :padless="padless" class="footer-color">
        <v-row class="px-3 d-flex justify-center">
          <v-col cols="3" class="d-flex px-0 py-0 justify-center">
            <v-btn class="my-10" icon @click="routeLink('/')">
              <div class="just-con">
                <div class="shade">
                  <v-icon class="icon-text pt-3" size="24px"> mdi-home </v-icon>
                </div>
                <div class="item-text pt-4 text-truncate" style="max-width: 25vw">
                  {{ $t("footer.home") }}
                </div>
              </div>
            </v-btn>
          </v-col>
          <v-col cols="3" class="d-flex px-0 py-0 justify-center">
            <v-btn class="my-10" icon @click="routeLink('/favorites')">
              <div class="just-con">
                <div class="shade">
                  <v-icon class="icon-text pt-3" size="24px"> mdi-star-outline </v-icon>
                </div>
                <div class="item-text pt-4 text-truncate" style="max-width: 25vw">
                  {{ $t("footer.favorites") }}
                </div>
              </div>
            </v-btn>
          </v-col>
          <v-col cols="3" class="d-flex px-0 py-0 justify-center">
            <!-- <v-btn
            @click="reload()"
            x-small
            v-if="checkHomePage"
            outlined
            class="mt-1"
          >
            {{ $t("home.refresh") }}
            <v-icon x-small class="mx-1">mdi-refresh</v-icon>
          </v-btn> -->
            <v-btn class="my-10" icon @click="reload()">
              <div class="just-con">
                <div class="shade">
                  <v-icon class="icon-text pt-3" size="24px"> mdi-refresh </v-icon>
                </div>
                <div class="item-text pt-4 text-truncate" style="max-width: 25vw">
                  {{ $t("home.refresh") }}
                </div>
              </div>
            </v-btn>
          </v-col>
        </v-row>
      </v-footer>
    </v-card>
  </div>
</template>

<script>
import gameService from "@/services/gameService";
import NoSleep from "nosleep.js";
export default {
  data: () => ({
    padless: true,
    variant: "absolute",
    zIndex: 100,
    nosleep: null,
  }),
  computed: {
    localAttrs() {
      const attrs = {};
      if (this.variant === "default") {
        attrs.absolute = false;
        attrs.fixed = false;
      } else {
        attrs[this.variant] = true;
      }
      return attrs;
    },
  },
  methods: {
    async routeLink(link) {
      if (link == "/favorites") {
        if (this.$nosleep) this.$nosleep.disable();
        this.$nosleep = new NoSleep();
        this.$nosleep.enable();
        try {
          const result = await gameService.authenticateSubscribedUser();
          if (result.status == 200 && result.data.isVerified == true) {
            this.$router.push(link);
          }
        } catch (error) {
          console.log(error);
          if (error.response.status == 404 && error.response.data == "Not Subscribed") {
            this.$store.commit("snackbar/setSnackbar", {
              content: this.$t("Please Subscribe to Watch Match"),
              color: "error ",
              isVisible: true,
            });
            this.$router.push("/subscription");
          }
          console.log(error);
        }
      } else {
        this.$router.push(link);
      }
    },
    reload() {
      location.reload(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-card {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 10px 10px 15px 10px #888888 !important;
  z-index: 100;
}
.footer-color {
  background-color: #121212 !important;
  box-shadow: 0px -4px 12px rgba(137, 137, 137, 0.16) !important;
}
.icon-text {
  color: #7fc7d9 !important;
}
.item-text {
  font-family: "Times New Roman", Times, serif;
  color: white !important;
}
.just-con {
  display: flex;
  flex-direction: column !important;
  align-items: center !important;
}
@media only screen and (max-width: 432px) {
  .just-con {
    font-size: vw;
  }
  .item-text {
    font-size: 0.65rem;
  }
}
</style>
