<template>
  <div :class="$vuetify.breakpoint.smAndDown ? '' : 'base-bg px-8 py-4'">
    <div v-if="loading" class="d-flex justify-center">
      <circularLoader />
    </div>
    <section class="home-section" v-else>
      <Header :title="$t('welcome')" :planDetails="userData.planDetails" />
      <div class="mt-4">
        <h4 v-if="notification" class="text-center red-bg rounded">
          {{ notification }}
        </h4>
        <!-- <h3
            v-if="userData && userData.adminMessage"
            class="text-center base-bg py-e1"
            >
            {{ userData.adminMessage + ` ${userData.username}`}}
          </h3> -->
        <marquee v-if="userData && userData.message" class="white-txt">{{
          userData.message
        }}</marquee>
        <div
          class="mx-4 base-bg p-12"
          style="margin-bottom: 8px"
          :class="{ 'mt-8': notification }"
        >
          <v-row>
            <v-tabs height="">
              <v-tabs-slider color="#7FC7D9"></v-tabs-slider>
              <v-col class="py-0 px-0 base-bg">
                <v-tab class="font-16 py-2 px-0" href="#live">
                  {{ $t("home.live") }} ({{ liveCount }})
                </v-tab>
              </v-col>
              <v-col class="py-0 px-0 base-bg">
                <v-tab class="font-16 py-2 px-0" href="#upcoming">
                  {{ $t("home.schedule") }} ({{ upcomingCount }})
                </v-tab>
              </v-col>
              <v-tab-item class="base-bg" value="live">
                <Live @countLive="countLive" />
              </v-tab-item>
              <v-tab-item class="base-bg" value="upcoming">
                <Upcoming />
              </v-tab-item>
            </v-tabs>
          </v-row>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Upcoming from "../components/home/UpcomingMatch.vue";
import Live from "../components/home/LiveMatch.vue";
import Header from "../components/Header.vue";
import gameService from "@/services/gameService.js";
import * as SocketioService from "../services/socketioService.js";
import loginService from "@/services/userService.js";
import circularLoader from "@/components/circularLoader.vue";
import moment from "moment";

export default {
  name: "Home",
  components: {
    Upcoming,
    Live,
    Header,
    circularLoader,
  },
  data() {
    return {
      tabs: ["Live", "Upcoming"],
      liveCount: 0,
      upcomingCount: 0,
      loading: false,
      userData: undefined,
      notification: undefined,
    };
  },
  async created() {
    this.loading = true;
    SocketioService.eventBus.$on("update-games", () => {
      this.getUpcomingGamesCount();
    });
    this.getUpcomingGamesCount();
    try {
      const result = await loginService.getUserDetails(
        this.$store.state.user.userId
      );
      if (result.status == 200 && result?.data?.isError === false) {
        this.userData = result.data.data;
        if (result.data.data.status == "blocked") {
          this.notification =
            "Your account is blocked, kindly contact your admin";
        } else if (result.data.data.planDetails) {
          const date1 = moment(result.data.data.planDetails.endDate);
          const date2 = moment();
          const diff = date1.diff(date2, "days") + 1;
          this.userData.planDetails.diff = diff;
          if (result.data.data.planDetails.status == "active" && diff <= 3) {
            this.notification =
              this.$t("home.yourPlanwillExpireOn") +
              " " +
              new Date(result.data.data.planDetails.endDate.slice(0, -1))
                .toString("en-US", {
                  timeZone: "Asia/Kolkata",
                })
                .slice(4, 15);
          }
          if (result.data.data.planDetails.status == "expired") {
            this.notification =
              "Your recharge has expired, kindly contact admin to recharge";
          }
        }
        if (
          result.data.data.deviceType == "Android-Web" &&
          ([
            "iPad Simulator",
            "iPhone Simulator",
            "iPod Simulator",
            "iPad",
            "iPhone",
            "iPod",
          ].includes(navigator.platform) ||
            (navigator.userAgent.includes("Mac") && "ontouchend" in document))
        ) {
          this.$store.commit("snackbar/setSnackbar", {
            content:
              "Please login from android/web or contact admin to get Iphone account",
            color: "error",
            isVisible: true,
          });
          this.$router.push({
            path: "/login",
          });
        }
      } else {
        this.$store.commit("snackbar/setSnackbar", {
          content: result?.data?.message || "Something went wrong",
          color: "error",
          isVisible: true,
        });
        this.$router.push({
          path: "/login",
        });
      }
    } catch (error) {
      console.log(error);
      this.$store.commit("snackbar/setSnackbar", {
        content: "Something went wrong",
        color: "error",
        isVisible: true,
      });
      this.$router.push({
        path: "/login",
      });
    }
    this.loading = false;
  },
  beforeRouteLeave(to, from, next) {
    if (to.path.includes("/livestream/") || to.path.includes("/favorites")) {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        try {
          var elem = document.getElementById("app");
          if (elem.requestFullscreen) {
            elem.requestFullscreen();
          } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
          } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
          } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
          } else if (elem.webkitEnterFullscreen) {
            elem.webkitEnterFullscreen();
          }
          // screen.orientation.lock("landscape-primary");
          if (screen.orientation && screen.orientation.lock) {
            screen.orientation
              .lock("landscape-primary")
              .then(() => {
                // Lock successful
              })
              .catch((error) => {
                console.error("Error locking screen orientation:", error);
              });
          } else {
            console.warn(
              "Screen orientation lock is not supported on this device."
            );
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
    next();
  },
  methods: {
    countLive(value) {
      this.liveCount = value;
    },
    async getUpcomingGamesCount() {
      try {
        const data = {
          on1Sports: true,
        };
        const result = await gameService.getUpcomingGamesLight(data);
        if (result.status == 200) {
          this.upcomingCount = result.data.length;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.notifier {
  color: white;
  font-size: 16px;
  font-weight: bolder;
}
.marginn {
  margin-top: 22px;
}
.dialog {
  position: relative;
}
.dialogButtons {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
}
@media only screen and (max-width: 800px) {
  .dialogButtons {
    bottom: 10px;
  }
}
@media only screen and (max-width: 500px) {
  .dialogButtons {
    bottom: 5px;
  }
}
.v-tab {
  border-radius: 10px;
  height: 100%;
  background-color: transparent !important;
  margin-top: auto;
  margin-bottom: auto;
  padding: 2rem;
}

.v-tabs-slider {
  display: none;
}
.v-tab--active {
  background: #fff !important;
  color: #3b99d1;
  font-weight: 600;
}
.home-section {
  width: 80%;
  margin-left: auto;

  @media only screen and (max-width: 768px) {
    width: 100% !important;
    margin-left: none;
  }
}

.v-tab--active[data-v-fae5bece] {
  color: #3b99d1;
}

.v-slide-group__wrapper {
  .v-slide-group__content {
    border: 1px solid white !important;
    padding: 0.5rem;
  }
}

// .cross-icon {
//   position: absolute;
//   right: 5px;
//   top: 5px
// }
</style>
