<template>
  <section class="flash-loader">
    <img class="blink" src="../assets/images/R5.svg" />
    <div class="loader-msg">Match Will Be Live Soon...</div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.flash-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  flex-direction: column;
  font-weight: 600;
  top: 0;
  left: 0;
}
.loader-msg {
}
.blink {
  animation: blink 1s steps(1, end) infinite;
  width: 95px;
  height: 60px;
  display: block;
  margin-bottom: 1rem;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
