import axios from "axios";
/* eslint-disable */
const apiUrl = process.env.VUE_APP_ENV_1SPORTS_API_URL;

export default {
  editFavorite(data) {
    return axios.post(`${apiUrl}/favorite/addOrUpdateFavorite`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.accessToken,
      },
    });
  },
  getFavoritesForUser(){
    return axios.get(`${apiUrl}/favorite/getFavoritesForUser`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.accessToken,
      },
    });
  }
};
