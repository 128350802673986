<template>
  <div
    :class="$vuetify.breakpoint.smAndDown ? '' : 'base-bg px-8 py-4'"
    class="section-wrap"
  >
    <Header title="Password" />
    <div class="justify-center pt-6 password-wrap" align="center">
      <div class="input-field mx-6">
        <div class="mt-3 password">
          <v-text-field
            :label="
              !$v.password.minLength && $v.password.$error
                ? passwordErrors
                : $t('placeholder.newPassword')
            "
            v-model="password"
            :error-messages="passwordErrors"
            outlined
            dense
            required
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show2 ? 'text' : 'password'"
            @click:append="show2 = !show2"
          >
          </v-text-field>
        </div>
        <div class="mt-3 password">
          <v-text-field
            :label="
              !$v.confirmPassword.minLength && $v.confirmPassword.$error
                ? confirmPasswordErrors
                : $t('placeholder.confirmPassword')
            "
            v-model="confirmPassword"
            :error-messages="confirmPasswordErrors"
            outlined
            dense
            required
            @input="$v.confirmPassword.$touch()"
            @blur="$v.confirmPassword.$touch()"
            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show3 ? 'text' : 'password'"
            @click:append="show3 = !show3"
          >
          </v-text-field>
        </div>
        <div class="py-7 text-center">
          <v-btn class="font-14 gold-btn py-5 black-txt" @click="submit()">{{
            $t("changepassword.submit")
          }}</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import Header from "@/components/Header.vue";
import userService from "@/services/userService";
export default {
  components: {
    Header,
  },
  mixins: [validationMixin],
  data() {
    return {
      password: "",
      confirmPassword: "",
      show2: false,
      show3: false,
      zIndex: 100,
    };
  },
  validations: {
    password: {
      required,
      maxLength: maxLength(24),
      minLength: minLength(4),
    },
    confirmPassword: {
      required,
      validateConfirmPassword(value) {
        return value === this.password;
      },
    },
  },
  computed: {
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.maxLength && errors.push(this.$t("validation.passwordMost"));
      !this.$v.password.minLength && errors.push(this.$t("validation.passwordLeast"));
      !this.$v.password.required && errors.push(this.$t("validation.passwordRequired"));
      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.confirmPassword.$dirty) return errors;
      !this.$v.confirmPassword.required &&
        errors.push(this.$t("validation.confirmPasswordRequired"));
      !this.$v.confirmPassword.validateConfirmPassword &&
        errors.push(this.$t("validation.passwordMatch"));
      return errors[0];
    },
  },
  methods: {
    async submit() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      try {
        const data = {
          password: this.password,
        };
        const result = await userService.resetPassword(data);
        if (result.status === 200 && result.data.isError === false) {
          this.$router.push("/");
          this.$store.commit("snackbar/setSnackbar", {
            content: this.$t("Password changed successfully"),
            color: "success",
            isVisible: true,
          });
        }
      } catch (e) {
        this.$store.commit("snackbar/setSnackbar", {
          content: "Something went wrong",
          color: "error ",
          isVisible: true,
        });
        console.log(e);
      }
    },
  },
};
</script>
<style scoped>
.password-wrap {
  margin-left: auto;
}
.password-wrap {
  height: 80vh !important;
  display: flex;
  align-items: center;
}

.input-field {
  width: 50%;
}
@media only screen and (max-width: 768px) {
  .password-wrap {
    width: 100%;
    margin-left: 0;
  }
  .password {
    width: 100% !important;
  }

  .input-field {
    width: 100%;
  }
}
</style>
