<template>
  <v-snackbar
    multi-line
    v-model="$store.state.snackbar.snackbar.isVisible"
    dark
    :timeout="2000"
    :color="$store.state.snackbar.snackbar.color"
    top
    center
  >
    <v-layout align-center>
      <v-layout column>
        <div class="font-16">{{ $store.state.snackbar.snackbar.content }}</div>
      </v-layout>
    </v-layout>
  </v-snackbar>
</template>
<script>
export default {
  name: "Snackbar",
  computed: {},
};
</script>
