import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "@/views/Login.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import LiveStream from "@/views/LiveStream.vue";
import installPwaIos from "@/views/installPwaIos.vue";
import Favorites from "@/views/Favorites.vue";
import Tv from "@/views/Tv.vue";
import auth from "./middleware/auth";
import middlewarePipeline from "./middlewarePipeline";
import store from "../store";
Vue.use(VueRouter);
const routes = [
  {
    path: "/index.html",
    component: Home,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    props: true,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    props: true,
  },
  {
    path: "/resetPassword",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/livestream/:id",
    name: "LiveStream",
    component: LiveStream,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/installPwaIos",
    name: "installPwaIos",
    component: installPwaIos,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/Favorites",
    name: "Favorites",
    component: Favorites,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/Tv",
    name: "Tv",
    component: Tv,
  },
  {
    path: "/*",
    component: 404,
  },
];
const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});
router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }
  const middleware = to.meta.middleware;

  const context = {
    to,
    from,
    next,
    store,
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});
export default router;
