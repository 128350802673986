<template>
  <div style="position: relative">
    <div class="removeLive" @click="remove">
      <v-icon>mdi-close</v-icon>
    </div>
    <stream
      v-if="favorite.playerType == 'nanocosmos'"
      :index="index"
      :streams="favorite.streams"
      :favorites="true"
      :count="count"
      :key="index"
      :id="favorite._id"
      :message="favorite.message"
    />
    <antmedia-player
      v-if="favorite.playerType == 'antmedia'"
      :index="index"
      :streams="favorite"
      :favorites="true"
      :count="count"
      :key="index"
      :id="favorite._id"
    />
    <new-player
      v-if="favorite.playerType == 'new'"
      :index="index"
      :streams="favorite"
      :favorites="true"
      :count="count"
      :key="index"
      :id="favorite._id"
    />
    <livetv-player
      v-if="favorite.playerType == 'livetv'"
      :index="index"
      :streams="favorite"
      :favorites="true"
      :count="count"
      :key="count"
      :id="favorite._id"
    />
    <div class="provider-logo">
      <img src="@/assets/images/LOGO SATSPORT 4X1 INCH.png" />
    </div>

    <div v-if="marketsVisible">
      <div class="oddsText" :class="{ moveToTop: count > 1 }">
        <div v-if="initialOddsLoaded">
          <div class="oddsHead mb-1">
            <span class="alignCenter"
              ><span class="black-transparent-patch" data-content="Match Odds">
                Match Odds
              </span></span
            >
          </div>
          <!-- <div class="d-flex justify-end">
            <span class="oddSubs">Back</span> <span class="oddSubs">Lay</span>
          </div> -->
          <div
            v-for="(odds, index) in oddsData"
            :key="odds.backPrice1 + odds.layPrice1 + index"
          >
            <div class="d-flex align-center justify-end mt-1">
              <span
                class="black-transparent-patch"
                style="margin-right: -4px"
                v-if="odds && odds.runner && !odds.runner.toUpperCase().includes('DRAW')"
                :data-content="odds.runner ? odds.runner : ''"
                >{{ odds.runner | teamNames }}</span
              >
              <span
                class="black-transparent-patch"
                style="margin-right: -4px"
                :data-content="odds.runner ? odds.runner : ''"
                v-else-if="odds && odds.runner"
                >{{ odds.runner }}</span
              >
              <div class="d-flex">
                <odds-box
                  v-if="
                    odds && odds.backPrice1 && odds.backSize1 && odds.status === 'ACTIVE'
                  "
                  :odds.sync="odds.backPrice1"
                  :volume.sync="odds.backSize1"
                  :color="'rgb(115,194,243)'"
                  :count="count"
                />
                <odds-box :count="count" v-else :odds="0" :volume="0" />
                <odds-box
                  v-if="
                    odds && odds.layPrice1 && odds.laySize1 && odds.status === 'ACTIVE'
                  "
                  :odds.sync="odds.layPrice1"
                  :volume.sync="odds.laySize1"
                  :color="'rgb(248, 180, 180)'"
                  :count="count"
                />
                <odds-box :count="count" v-else :odds="0" :volume="0" />
              </div>
            </div>
          </div>
        </div>
        <div v-if="initialBookMakerLoaded" class="mt-1">
          <div class="oddsHead">
            <span class="alignCenter"
              ><span class="black-transparent-patch" data-content="Bookmaker">{{
                "Bookmaker"
              }}</span></span
            >
          </div>
          <!-- <div class="d-flex justify-end">
            <span class="oddSubs">Back</span> <span class="oddSubs">Lay</span>
          </div> -->
          <div
            v-for="(data, index) in bookMakerData"
            :key="index + data.back + data.lay"
            style="margin-top: -5px"
          >
            <div
              class="d-flex align-center justify-end mt-2"
              v-if="!data.suspended && !data.ballrunning"
            >
              <span
                class="black-transparent-patch"
                v-if="!data.runner.toUpperCase().includes('DRAW')"
                :data-content="data.runner ? data.runner : ''"
                >{{ data.runner | teamNames }}</span
              >
              <span
                class="black-transparent-patch"
                :data-content="data.runner ? data.runner : ''"
                v-else
                >{{ data.runner }}</span
              >
              <!-- <div v-if="bookMarketStatus == 'Disable'">
                  <oddsMessage-box message="Market Suspended" />
                </div>
                <div v-else-if="data.status == 'Disable'">
                  <oddsMessage-box message="Suspended" />
                </div> -->
              <div class="d-flex">
                <odds-box
                  v-if="data && data.back"
                  :odds.sync="data.back"
                  :color="'rgb(115,194,243)'"
                  :count="count"
                  :isbook="true"
                />
                <odds-box :count="count" v-else :odds="0" />
                <odds-box
                  v-if="data && data.lay"
                  :odds.sync="data.lay"
                  :color="'rgb(248, 180, 180)'"
                  :count="count"
                  :isbook="true"
                />
                <odds-box :count="count" v-else :odds="0" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="initialSessionsLoaded && typeof sessions === 'object'"
        class="sessionsText"
        :class="{ moveToTop: count > 1 }"
      >
        <div class="oddsHead mb-1" style="justify-content: flex-start !important">
          <span class="alignCenter"
            ><span data-content="Fancy Markets" class="black-transparent-patch"
              >Fancy Markets</span
            ></span
          >
        </div>
        <!-- <div class="d-flex">
          <span class="oddSubs">No</span> <span class="oddSubs">Yes</span>
        </div> -->
        <div v-for="item in sessions" :key="item.time">
          <div
            class="d-flex align-center mt-1"
            v-if="!item.ball_running && !item.ballrunning && !item.suspended"
            :class="{ hidden: hidDecimalOdds(item.title) }"
          >
            <!-- <div v-if="item.isMarketSuspended">
              <oddsMessage-box :favorites="true" message="Market Suspended" />
            </div>
            <div v-else-if="item.ballChalu">
              <oddsMessage-box :favorites="true" message="Bowl Running" />
            </div>
            <div v-else-if="item.isSuspended">
              <oddsMessage-box :favorites="true" message="Suspended" />
            </div> -->
            <div class="d-flex">
              <odds-box
                :favorites="true"
                :odds.sync="item.no"
                :volume.sync="item.no_rate"
                :color="'rgb(248, 180, 180)'"
                :fancy="true"
                :count="count"
              />
              <odds-box
                :favorites="true"
                :odds.sync="item.yes"
                :volume.sync="item.yes_rate"
                :color="'rgb(115,194,243)'"
                :fancy="true"
                :count="count"
              />
            </div>
            <span class="black-transparent-patch" :data-content="item.title">{{
              item.title
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="initialOddsLoaded || initialBookMakerLoaded || initialSessionsLoaded"
      class="oddsVisibility"
    >
      <v-icon
        color="#7FC7D9"
        v-if="marketsVisible"
        dark
        @click="marketsVisible = !marketsVisible"
      >
        mdi-eye
      </v-icon>
      <v-icon color="red" v-else dark @click="marketsVisible = !marketsVisible">
        mdi-eye-off
      </v-icon>
    </div>
  </div>
</template>
<script>
import stream from "@/components/NanoPlayer.vue";
import AntmediaPlayer from "../components/AntmediaPlayer.vue";
// import * as playSocketService from "@/services/playSocketService.js";
import * as SocketioService from "@/services/socketioService.js";
import oddsBox from "@/components/oddsBox.vue";
import oddsMessageBox from "@/components/oddsMessageBox.vue";
import NewPlayer from "../components/NewPlayer.vue";
import LivetvPlayer from "./LiveTvPlayer.vue";
import AdsBanner from "./AdsBanner.vue";
export default {
  components: {
    stream,
    AntmediaPlayer,
    oddsBox,
    oddsMessageBox,
    NewPlayer,
    LivetvPlayer,
    AdsBanner,
  },
  props: ["favorite", "index", "count", "remove"],
  data() {
    return {
      eventId: this.favorite.eventId,
      // marketId: undefined,
      // sessionId: undefined,
      // bookMakerId: undefined,
      oddsData: [],
      bookMakerData: [],
      // bookMakerSuspened: false,
      // bookMakerBallRunning: false,
      sessions: [],
      initialOddsLoaded: false,
      initialSessionsLoaded: false,
      initialBookMakerLoaded: false,
      marketsVisible: true,
      showTooltip: true,
      tooltipTimer: undefined,
    };
  },
  async mounted() {
    await this.OddsManage();
    await this.oddsUpdateService();
    await this.gameUpdateService();
    this.socketReconnection();
    this.hideTooltip();
  },
  methods: {
    async joinOddsSockets() {
      if (this.eventId)
        await SocketioService.socket.joinRooms({
          roomNames: [this.eventId, this.eventId + "-BM", this.eventId + "-FM"],
        });
    },
    async socketReconnection() {
      SocketioService.eventBus.$on("reconnected", async () => {
        console.log("reconnected");
        this.joinOddsSockets();
      });
      // SocketioService.eventBus.$on("reconnectedPlaySocket", async () => {
      //   console.log("reconnected");
      //   this.joinPlaySockets();
      // });
    },
    async oddsUpdateService() {
      SocketioService.eventBus.$on("oddsData", async (data) => {
        if (
          data.event == this.eventId &&
          data.args &&
          data.args.length > 0 &&
          data.args[0] &&
          data.args[0].odds &&
          data.args[0].odds.length > 0
        ) {
          this.assignOdds(data.args[0].odds);
        }
        if (
          data.event == this.eventId + "-FM" &&
          data.args &&
          data.args.length > 0 &&
          data.args[0]
        ) {
          if (
            data.event == this.eventId + "-FM" &&
            data.args &&
            data.args.length > 0 &&
            data.args[0]
          ) {
            if (data.args[0].fancyData && data.args[0].fancyData.length > 0) {
              this.sessions = data.args[0].fancyData;
              console.log(data.args, "SESSIONS");
              this.initialSessionsLoaded = true;
            } else {
              this.initialSessionsLoaded = false;
            }
          }
        }
        if (
          data.event == this.eventId + "-BM" &&
          data.args &&
          data.args.length > 0 &&
          data.args[0]
        ) {
          this.assignBookMaker(data.args[0].runners);
        }
      });
    },
    async gameUpdateService() {
      SocketioService.eventBus.$on("updateGameData", async (data) => {
        if (
          data.eventId != this.eventId &&
          (data.id == this.$route.params.id || data.id == this.id)
        ) {
          if (this.eventId)
            await SocketioService.socket.leaveRooms({
              roomNames: [this.eventId, this.eventId + "-BM", this.eventId + "-FM"],
            });
          this.eventId = data.eventId;
          this.OddsManage();
        }
      });
      SocketioService.eventBus.$on("updateBanners", async (data) => {
        console.log(data);
        // if (
        //   data.eventId != this.eventId &&
        //   (data.id == this.$route.params.id || data.id == this.id)
        // ) {
        // if (this.eventId)
        //   await SocketioService.socket.leaveRooms({
        //     roomNames: [this.eventId, this.eventId + "-BM", this.eventId + "-FM"],
        //   });
        // this.eventId = data.eventId;
        await this.OddsManage();
        // }
      });
    },
    // async gameUpdateService() {},
    async OddsManage() {
      console.log("odds manage", this.eventId);
      if (this.eventId) {
        await this.joinOddsSockets();
      }
    },
    // async getOddsFromApi() {
    //   try {
    //     const obj = {
    //       accessLevel: "Player",
    //       game: "Cricket",
    //       parentId: this.sessionId,
    //     };
    //     const result = await playOddsService.getOddsPerGameFromPlay(obj);
    //     if (result.status == 200) {
    //       if (
    //         result.data &&
    //         result.data.data &&
    //         result.data.data[0] &&
    //         result.data.data[0].runners &&
    //         result.data.value
    //       ) {
    //         this.assignOdds(result.data.data[0].runners);
    //       }
    //     }
    //   } catch (error) {
    //     console.log(error);
    //     return error;
    //   }
    // },
    // async getSessionsFromApi() {
    //   try {
    //     const obj = {
    //       market: this.sessionId,
    //     };
    //     const result = await playOddsService.getSessionsPerGameFromPlay(obj);
    //     if (result.status == 200) {
    //       this.initialSessionsLoaded = true;
    //       this.sessions = result.data;
    //     }
    //   } catch (error) {
    //     console.log(error);
    //     return error;
    //   }
    // },
    // async getBookMakerFromApi() {
    //   try {
    //     const obj = {
    //       parentId: this.sessionId,
    //     };
    //     const result = await playOddsService.getBookMakerPerGameFromPlay(obj);
    //     if (result.status == 200) {
    //       if (
    //         result.data &&
    //         result.data.data &&
    //         result.data.data[0] &&
    //         result.data.data[0].runners &&
    //         result.data.value
    //       ) {
    //         this.assignBookMaker(
    //           result.data.data[0].runners,
    //           result.data.data[0].marketStatus
    //         );
    //         this.bookMakerId = result.data.data[0].betfairId;
    //       }
    //     }
    //   } catch (error) {
    //     console.log(error);
    //     return error;
    //   }
    // },
    assignOdds(data) {
      console.log("%c", "color: #007acc;", {
        bp1: data[0].backPrice1,
        bs1: data[0].backSize1,
        lp1: data[0].layPrice1,
        ls2: data[0].laySize1,
      });
      console.log("%c", "color: red;", {
        bp1: data[1].backPrice1,
        bs1: data[1].backSize1,
        lp1: data[1].layPrice1,
        ls2: data[1].laySize1,
      });
      // console.log("%cVideo.vue line:408 avalableSize", "color: #007acc;", data[0]);
      this.oddsData = data;
      this.initialOddsLoaded = true;
    },
    assignBookMaker(data) {
      console.log("%cBookMaker1", "color: white;", {
        back: data[0].back,
        lay: data[0].lay,
      });
      console.log("%cBookMaker2", "color: white;", {
        back: data[1].back,
        lay: data[1].lay,
      });
      this.bookMakerData = data;
      // if (suspended == "1") this.bookMakerSuspened = true;
      // else this.bookMakerSuspened = false;
      // if (ballrunning == "1") this.bookMakerBallRunning = true;
      // else this.bookMakerBallRunning = false;
      this.initialBookMakerLoaded = true;
    },
    async leaveOddsSockets() {
      if (this.eventId)
        await SocketioService.socket.leaveRooms({
          roomNames: [this.eventId, this.eventId + "-BM", this.eventId + "-FM"],
        });
    },
    hideTooltip() {
      if (this.tooltipTimer) clearTimeout(this.tooltipTimer);
      this.tooltipTimer = setTimeout(
        function () {
          this.showTooltip = false;
        }.bind(this),
        1500
      );
    },
    hidDecimalOdds(data) {
      if (data && data.split(" ")[0] && !isNaN(+data.split(" ")[0])) {
        return !Number.isInteger(+data.split(" ")[0]);
      }
    },
  },
  async beforeDestroy() {
    if (this.tooltipTimer) {
      clearTimeout(this.tooltipTimer);
    }
    await this.leaveOddsSockets();
  },
};
</script>
<style lang="scss" scoped>
.favorite-img {
  position: relative;
}
.hart-btn {
  position: absolute;
  top: 5px;
  right: 5px;
}
.hart-btn {
  opacity: 0.5;
}
.mar {
  margin-right: 10px;
}
.oddsVisibility {
  position: absolute;
  bottom: 47px;
  left: 15px;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.52);
  display: inline;
}
.oddsText {
  color: white !important;
  position: absolute;
  top: 30px;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 0.5rem;
  right: 0;
  /* transform: translate(0, -50%); */
  z-index: 1000;
}
.sessionsText {
  color: white !important;
  position: absolute;
  top: 15px;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 0.5rem;
  left: 0;
  /* transform: translate(0, -50%); */
  z-index: 1000;
}
.moveToTop {
  top: 22px !important;
}
.oddsHead {
  display: flex;
  justify-content: flex-end;
  text-decoration: underline;
  font-size: 0.5rem;
  font-weight: bold;
}
/* .oddSubs {
  min-width: 30px;
  text-align: center;
  margin-left: 2.5px;
} */
.alignCenter {
  text-align: center;
  width: 140px;
}
.livetvCentre {
  display: flex;
  align-items: center;
  height: 100%;
}
@media only screen and (min-width: 1000px) {
  .oddSubs {
    min-width: 40px;
  }
  .alignCenter {
    width: 140px;
  }
  .oddsText {
    font-size: 0.6rem;
  }
  .sessionsText {
    font-size: 0.6rem;
  }
  .oddsHead {
    font-size: 0.625rem;
  }
  /* .oddsHead {
    font-size: 0.6rem;
  } */
}
@media only screen and (max-width: 700px) {
  .oddsText {
    font-size: 0.3rem;
  }
  /* .oddSubs {
    min-width: 15px;
  } */
  .sessionsText {
    font-size: 0.3rem;
  }
  .oddsHead {
    font-size: 0.3rem;
  }
  .alignCenter {
    width: 140px;
  }
}
.removeLive {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  z-index: 1000;
}
.black-transparent-patch {
  background-color: rgba(0, 0, 0, 0.8);
  font-family: "roboto";
  font-family: 900;
}
.provider-logo {
  position: absolute;
  top: 5px;
  left: 25%;
  z-index: 100;
  width: 5rem;

  img {
    width: 100%;
    height: 100%;
    background-size: cover;
  }
}
.hidden {
  display: none !important;
}
</style>
