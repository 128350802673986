<template>
  <v-skeleton-loader
    type="list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line"
  ></v-skeleton-loader>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
