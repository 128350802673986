<template>
  <div style="bacground: black !important">
    <div v-if="loading" class="d-flex justify-center">
      <flash-loader />
    </div>
    <div>
      <v-row v-if="loaded" no-gutters style="background: black !important">
        <v-col
          v-for="liveStream in liveStreamDetails"
          :key="gameId"
          :cols="liveStreamDetails.length > 3 ? 6 : 12"
          :sm="liveStreamDetails.length > 1 ? 6 : 12"
        >
          <Video
            :favorite="liveStream"
            @reRender="getLiveStreamDetails"
            :index="gameId"
            :count="liveStreamDetails.length"
            :key="gameId"
            :remove="() => removeLive(liveStream)"
            class="relative"
          />

          <div v-if="display" class="new">{{ name }}</div>
          <div v-if="displayWebsite" class="new">
            {{ website }}
          </div>
        </v-col>
      </v-row>

      <div class="toolBar d-flex flex-column">
        <v-icon center class="mt-1 mb-3" size="24" @click="back" color="#60baef"
          >mdi-backburger</v-icon
        >
        <v-icon
          center
          color="white"
          size="24"
          @click="toggle()"
          v-if="!isFullscreen && !isIos && !loading && liveMatches.length != 0"
        >
          mdi-fullscreen
        </v-icon>
        <v-icon
          center
          size="24"
          color="white"
          @click="toggle()"
          v-if="
            isFullscreen && !isMobile && !isIos && !loading && liveMatches.length != 0
          "
        >
          mdi-fullscreen-exit
        </v-icon>
      </div>
    </div>
    <div
      class="live-strip"
      v-if="liveGames.length > 1"
      :class="{ selected: showStrip, dismiss: !showStrip }"
    >
      <div v-for="(game, i) in liveGames" :key="i">
        <div
          v-if="game._id !== $route.params.id"
          class="fav-live-game-wrap"
          @click="addLiveGame(game)"
        >
          <div class="fav-live-game">
            {{ game.name }}
          </div>
        </div>
      </div>
    </div>
    <!-- <div
      v-if="liveGames.length > 0"
      class="live-strip"
      :class="{ selected: showStrip, dismiss: !showStrip }"
    >
      <div
        v-for="(game, i) in liveGames"
        class="fav-live-game-wrap"
        :key="i"
        @click="addLiveGame(game)"
      >
        <div v-if="game._id !== $route.params.id" class="fav-live-game">
          {{ game.name }}
        </div>
      </div>
    </div> -->
    <div
      class="strip-hamburger"
      v-if="liveGames.length > 1"
      @click="showStrip = !showStrip"
    >
      <v-icon> {{ showStrip ? "mdi-close" : "mdi-menu" }} </v-icon>
    </div>
  </div>
</template>
<script>
import favoriteService from "@/services/favoriteService";
import Header from "@/components/Header.vue";
import Video from "@/components/Video";
import CircularLoader from "../components/circularLoader.vue";
import * as SocketioService from "@/services/socketioService.js";
import gameService from "@/services/gameService";
import FlashLoader from "@/components/flashLoader.vue";
export default {
  data() {
    return {
      liveMatches: [],
      zIndex: 100,
      loading: false,
      isIos: false,
      isMobile: false,
      isFullscreen: false,
      display: false,
      usernameTurn: true,
      displayWebsite: false,
      websiteTurn: false,
      name: this.$store.state.user.username,
      website: window.location.hostname,
      interval1: null,
      interval2: null,
      liveGames: [],
      liveStreamDetails: [],
      loaded: false,
      showStrip: false,
    };
  },
  components: {
    Video,
    Header,
    CircularLoader,
    FlashLoader,
  },
  async mounted() {
    this.liveMatches.push(this.gameId);
    this.checkIos();
    this.checkMobile();
    // this.getLiveStreamDetails();
    this.handleLiveMatch(this.gameId);
    await this.getLiveGames();
  },
  methods: {
    checkIos() {
      if (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      ) {
        this.isIos = true;
      }
    },
    checkMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        this.isMobile = true;
      }
    },
    async joinSockets() {
      await SocketioService.socket.joinRooms({
        roomNames: ["1sportsstream" + this.$store.state.user.userId, "streamRoom"],
      });
      await SocketioService.socket.streamRoom({
        roomName: "1sportsstream" + this.$store.state.user.userId,
        accessToken: localStorage.accessToken,
      });
    },
    showUserNameService() {
      SocketioService.eventBus.$on("show-username", async () => {
        this.display = true;
        setTimeout(
          function () {
            this.display = false;
          }.bind(this),
          process.env.VUE_APP_ENV_SHOWUSERNAME_TIMING
        );
      });
    },
    showWebsiteService() {
      SocketioService.eventBus.$on("show-website", async () => {
        this.displayWebsite = true;
        setTimeout(
          function () {
            this.displayWebsite = false;
          }.bind(this),
          process.env.VUE_APP_ENV_SHOWUSERNAME_TIMING
        );
      });
    },
    socketReconnection() {
      SocketioService.eventBus.$on("reconnected", async () => {
        console.log("reconnected");
        await SocketioService.socket.joinRoom({ roomName: "streamRoom" });
      });
    },
    displayUsernameAndWebsite() {
      this.interval1 = setInterval(() => {
        if (this.usernameTurn) {
          this.display = true;
          setTimeout(
            function () {
              this.display = false;
            }.bind(this),
            process.env.VUE_APP_ENV_SHOWUSERNAME_TIMING
          );
        }
        this.usernameTurn = !this.usernameTurn;
      }, 600000);
      this.interval2 = setInterval(() => {
        if (this.websiteTurn) {
          this.displayWebsite = true;
          setTimeout(
            function () {
              this.displayWebsite = false;
            }.bind(this),
            process.env.VUE_APP_ENV_SHOWUSERNAME_TIMING
          );
        }
        this.websiteTurn = !this.websiteTurn;
      }, 600000);
    },
    async getLiveStreamDetails() {
      this.loaded = false;
      this.loading = true;
      this.renderLiveMatches();
      this.loading = false;
    },
    async back() {
      await this.$dialog
        .confirm({
          text: this.$t("Do you really want to exit?"),
          actions: {
            false: this.$t("popups.no"),
            true: this.$t("popups.yes"),
          },
        })
        .then((res) => {
          if (res == true) {
            this.$router.push("/");
          }
        });
    },
    toggle() {
      if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
      ) {
        this.exitFullscreen1();
      } else {
        this.requestFullscreen1();
      }
    },
    manageFullscreen() {
      setTimeout(async () => {
        if (
          document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.msFullscreenElement
        ) {
          this.isFullscreen = true;
        } else {
          this.isFullscreen = false;
          if (this.isMobile && !this.isIos) {
            await this.$dialog
              .confirm({
                text: "Enter Full-screen?",
                actions: {
                  false: this.$t("popups.no"),
                  true: this.$t("popups.yes"),
                },
              })
              .then((res) => {
                if (res == true) {
                  this.requestFullscreen1();
                }
              });
          }
        }
        window.addEventListener("fullscreenchange", (event) => {
          if (
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.msFullscreenElement
          ) {
            this.isFullscreen = true;
          } else {
            this.isFullscreen = false;
          }
        });
      }, 2000);
    },
    requestFullscreen1() {
      var elem = document.getElementById("app");
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitEnterFullscreen) {
        elem.webkitEnterFullscreen();
      }
      // screen.orientation.lock("landscape-primary");
      if (screen.orientation && screen.orientation.lock) {
        screen.orientation
          .lock("landscape-primary")
          .then(() => {
            // Lock successful
          })
          .catch((error) => {
            console.error("Error locking screen orientation:", error);
          });
      } else {
        console.log("Screen orientation lock is not supported on this device.");
      }
    },
    exitFullscreen1() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozRequestFullScreen) {
        document.mozExitFullScreen();
      }
      screen?.orientation?.unlock();
    },
    async getLiveGames() {
      try {
        const data = {
          user: this.$store.state.user.userId,
          // type: this.type == 0 ? "" : this.tags[this.type],
        };
        const result = await gameService.getLiveGamesLight(data);
        if (result.status == 200) {
          this.liveGames = result.data.data.data;
        }
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    async editFavorite(gamesData) {
      const data = {
        gameId: gamesData._id,
        userId: this.$store.state.user.userId,
        status: !gamesData.favorite ? "enabled" : "disabled",
      };
      try {
        const result = await favoriteService.editFavorite(data);
        if (result.status == 200) {
          if (result.data == this.$t("You can't add more than 4 liveMatches")) {
            this.$store.commit("snackbar/setSnackbar", {
              content: result.data,
              color: "error",
              isVisible: true,
            });
          } else {
            this.$emit("onEdit");
            this.$store.commit("snackbar/setSnackbar", {
              content:
                result.data.status == "enabled"
                  ? this.$t("Added to Favorite")
                  : this.$t("Removed from Favorite"),
              color: "success",
              isVisible: true,
            });

            this.getLiveGames();
            this.getLiveStreamDetails();
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async addLiveGame(game) {
      this.$router.push({ path: `/livestream/${game._id}` });
      this.liveStreamDetails = [];
      this.handleLiveMatch(game._id);
    },
    // async addLiveGame(game) {
    //   if (this.liveMatches.length >= 4) {
    //     this.$store.commit("snackbar/setSnackbar", {
    //       content: "You can't add more than 4 games",
    //       color: "error",
    //       isVisible: true,
    //     });
    //     return;
    //   }

    //   if (this.liveMatches.some((match) => match === game._id)) {
    //     this.$store.commit("snackbar/setSnackbar", {
    //       content: "Match already exist",
    //       color: "error",
    //       isVisible: true,
    //     });
    //     return;
    //   } else {
    //     this.liveMatches.push(game._id);
    //     this.handleLiveMatch(game._id);
    //   }
    // },
    async removeLive(game) {
      this.liveStreamDetails = this.liveStreamDetails.filter(
        (item) => item._id !== game._id
      );
      this.liveMatches = this.liveMatches.filter((match) => match !== game._id);

      if (this.liveStreamDetails.length === 0) {
        this.$router.push("/");
      }
    },
    async handleLiveMatch(gameId) {
      try {
        const result = await gameService.getLiveStreamDetails(gameId);
        if (result.status == 200 && result?.data?.isError === false) {
          const decrypted = this.$CryptoJS.AES.decrypt(
            result.data.data,
            process.env.VUE_APP_ENV_CRYPTO_KEY
          ).toString(this.$CryptoJS.enc.Utf8);
          this.liveStreamDetails.push(JSON.parse(decrypted));
          await this.joinSockets();
          this.showUserNameService();
          this.showWebsiteService();
          this.socketReconnection();
          this.displayUsernameAndWebsite();
          this.manageFullscreen();
        } else {
          // result?.data?.message
          if (
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.msFullscreenElement
          ) {
            this.exitFullscreen1();
          }
          if (result?.data?.message == "Not Subscribed") {
            this.$store.commit("snackbar/setSnackbar", {
              content: this.$t("Please Subscribe to see Favorites"),
              color: "error ",
              isVisible: true,
            });
            this.$router.push("/subscription");
          }
          console.log(error);
        }
        this.loaded = true;
      } catch (error) {
        if (
          document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.msFullscreenElement
        ) {
          this.exitFullscreen1();
        }
        if (
          error.response &&
          error.response.status == 404 &&
          error.response.data == "Not Subscribed"
        ) {
          this.$store.commit("snackbar/setSnackbar", {
            content: this.$t("Please Subscribe to see Favorites"),
            color: "error ",
            isVisible: true,
          });
          this.$router.push("/subscription");
        }
        console.log(error);
      }
    },
  },
  computed: {
    gameId() {
      return this.$route.params.id;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isIos) {
      next();
      window.location = window.location;
    } else {
      next();
    }
  },
};
</script>
<style lang="scss" scoped>
.live-strip {
  position: fixed;
  width: 100vw;
  bottom: 0;
  display: flex;
  padding-left: 2rem;
  gap: 2rem;
  overflow-x: scroll;
  background: black;
  font-weight: 600;
  z-index: 999;
  left: -100vw;
}
.fav-live-game {
  position: relative;
  padding-left: 30px;
  border-radius: 5px;
  padding: 0.5rem 1rem 0.5rem 30px;
  white-space: nowrap;
  cursor: pointer;
  background: transparent linear-gradient(90deg, #73bdf1, #0c3261) !important;
  border-radius: 50px;
}
.fav-live-game::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #0000001a;
  background: red;
  animation: pulse-animation 2s infinite;
  position: absolute;
  left: 15px;
  top: 40%;
}
.fav-live-game-wrap {
  padding-left: 20px;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 0, 0, 0.689);
  }
  100% {
    box-shadow: 0 0 0 6px rgba(255, 162, 162, 0);
  }
}

.toolBar {
  position: absolute;
  bottom: 70px;
  left: 10px;
  z-index: 999;
  background: rgba(0, 0, 0, 0.52);
  display: inline;
}
.strip-hamburger {
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 1000;
  .v-icon {
    background: rgba(0, 0, 0, 0.52);
    cursor: pointer;
    color: #60baef !important;
  }
}
.black-transparent-patch {
  background-color: rgba(0, 0, 0, 0.8);
}

.selected {
  animation: slide-in 0.25s forwards;
  -webkit-animation: slide-in 0.25s forwards;
}

.dismiss {
  animation: slide-out 0.25s forwards;
  -webkit-animation: slide-out 0.25s forwards;
}
@keyframes slide-in {
  0% {
    left: -100vw;
  }
  100% {
    left: 0;
  }
}

@-webkit-keyframes slide-in {
  0% {
    left: -100vw;
  }
  100% {
    left: 0;
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}
</style>
