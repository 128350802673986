<template>
  <div>
    <div v-if="loading">
      <sectionLoader />
    </div>
    <div v-else>
      <div v-if="upcomingGames.length == 0 && !loading" class="text-center">
        <img src="@/assets/images/pana.svg" aspect-ratio="1.5" class="my-5" />
        <p class="font-16">
          {{ $t("Unfortunately, No scheduled games found, Please try again later") }}
        </p>
      </div>
      <div v-else>
        <v-chip-group
          v-model="type"
          column
          mandatory
          class="mt-2 p-4 d-flex justify-start"
        >
          <v-chip v-for="tag in tags" :key="tag" filter>
            {{ tag }}
          </v-chip>
        </v-chip-group>
        <v-row no-gutters align="stretch">
          <v-col
            :cols="$vuetify.breakpoint.xs && upcomingGames.length > 0 ? 12 : 6"
            :md="upcomingGames.length > 4 ? 4 : 4"
            v-for="(i, index) in upcomingGames"
            :key="index"
            style="padding: 0px"
            class="mb-3 minht"
          >
            <MatchDetailsCard :gamesData="i" :upcoming="true" @onEdit="reRender" />
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
import gameService from "../../services/gameService.js";
import MatchDetailsCard from "@/components/MatchDetailsCard.vue";
import sectionLoader from "@/components/sectionLoader.vue";
import * as SocketioService from "../../services/socketioService.js";
export default {
  data() {
    return {
      upcomingGames: [],
      tags: ["All"],
      loading: false,
      type: 1,
    };
  },
  components: {
    MatchDetailsCard,
    sectionLoader,
  },
  created() {
    this.getTypeOfGames();
    SocketioService.eventBus.$on("update-games", () => {
      this.reRender();
    });
  },
  methods: {
    async getTypeOfGames() {
      this.loading = true;
      this.tags = ["All"];
      try {
        const data = {
          on1Sports: true,
        };
        const result = await gameService.getTypeOfGames(data);
        if (result.status == 200) {
          var others;
          var entertainment;
          result.data.forEach((element) => {
            if (element === "Others") {
              others = true;
            } else if (element === "Entertainment") {
              entertainment = true;
            } else {
              this.tags.push(element);
            }
          });
          if (entertainment) {
            this.tags.push("Entertainment");
          }
          if (others) {
            this.tags.push("Others");
          }
          this.getUpcomingGamesLight();
        }
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    async getUpcomingGamesLight() {
      this.loading = true;
      try {
        const data = {
          type: this.type == 0 ? "" : this.tags[this.type],
          on1Sports: true,
        };
        const result = await gameService.getUpcomingGamesLight(data);
        if (result.status == 200) {
          this.upcomingGames = result.data;
        }
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    reRender() {
      this.upcomingGames = [];
      this.getTypeOfGames();
    },
  },
  watch: {
    type: {
      async handler() {
        this.getUpcomingGamesLight();
      },
    },
  },
};
</script>
<style lang="scss"></style>
