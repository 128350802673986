<template>
  <div
    class="oddsBox d-flex flex-column justify-center"
    :style="{ background: color }"
    :class="{
      greybg: !odds && !volume,
      oddsBoxF: favorites,
      oddsBoxFancy: fancy,
      oddsFavHeight: count > 1 && isFav,
      odds4Height: count > 2 && isFav,
      bookHeight: isbook && count > 2 && isFav,
    }"
  >
    <div v-if="odds" class="odds" :class="{ oddsF: favorites, oddsSmallFont: count > 1 }">
      {{ fancy ? `${odds}` : `${numderOdd}` }}
    </div>

    <div
      v-if="volume"
      class="volume"
      :class="{ volumeF: favorites, volumeSmallFont: count > 1 }"
    >
      {{ volume }}
    </div>
    <div v-if="!odds && !volume">-</div>
  </div>
</template>

<script>
export default {
  props: ["odds", "volume", "color", "favorites", "fancy", "count", "isbook"],
  data() {
    return {
      newOdd: this.odds,
    };
  },
  mounted() {
    // console.log("%coddsBox.vue line:30 $route.path", "color: #007acc;", this.$route.path);
  },
  computed: {
    numderOdd() {
      if (typeof this.newOdd === "string" && this.newOdd.split(",")[1]) {
        this.newOdd = this.newOdd.replace(/,/g, "");
        return Number(this.newOdd).toFixed(0);
      }
      return Number(this.newOdd).toFixed(
        this.newOdd >= 100 ? 0 : this.newOdd >= 10 ? 1 : 2
      );
    },
    isFav() {
      return this.$route.path.toLowerCase() === "/favorites";
    },
  },
};
</script>

<style lang="scss" scoped>
.oddsBox {
  min-width: 55px;
  min-height: 45px;
  color: black;
  text-align: center;
  border: 2px solid black;
  margin-left: 5px;
  padding: 7px 0px 7px 0px !important;
  z-index: 10;
}
.oddsFavHeight {
  min-height: 30px;
  padding: 1px 0px 1px 0px !important;
  min-width: 35px !important;
  font-size: 8px !important;

  .volumeSmallFont {
    font-size: 6px !important;
  }
  .odds {
    font-size: 8px !important;
  }
}
.oddsBoxFancy {
  min-width: 50px;
  min-height: 25px;
  color: black;
  text-align: center;
  margin-left: 2px;
}
.oddsSmallFont {
  font-size: 10px;
}
.volumeSmallFont {
  font-size: 8px !important;
}
.smallOddBox {
  min-width: 40px !important;
}
.odds {
  font-size: 12px;
  font-weight: 800 !important;
  font-family: Unbounded !important;
}
.volume {
  font-size: 10px;
  line-height: 100%;
  font-weight: 500 !important;
  font-family: Unbounded !important;
}
.oddsBoxF {
  min-width: 45px;
  min-height: 20px;
  color: black;
  text-align: center;

  margin-left: 2px;
}

.oddsF {
  font-size: 10px;
  font-weight: 800 !important;
  font-family: Unbounded !important;
}
.volumeF {
  font-size: 8px;
  line-height: 100%;
  font-family: Unbounded !important;
}
.greybg {
  background: #e1e1e1 !important;
}
.odds4Height {
  min-height: 20px !important;
  min-width: 30px !important;
}
.bookHeight {
  min-height: 25px !important;
  .volumeSmallFont {
    font-size: 6px !important;
  }
  .odds {
    font-size: 8px !important;
  }
}
@media only screen and (min-width: 1000px) {
  .oddsBox {
    min-width: 65px;
    min-height: 50px;
    padding: 7px 0px 7px 0px !important;
  }
  .oddsFavHeight {
    min-width: 50px !important;
    min-height: 40px !important;
    font-size: 8px !important;
    padding: 3px 3px 3px 3px !important;

    .volumeSmallFont {
      font-size: 8px !important;
    }
    .odds {
      font-size: 10px !important;
    }
  }
  .oddsBoxFancy {
    min-width: 60px;
    min-height: 40px;
  }
  .odds {
    font-size: 14px;
  }
  .volume {
    font-size: 12px;
  }
  .oddsBoxF {
    min-width: 60px;
    min-height: 35px;
  }
  .oddsF {
    font-size: 12px !important;
  }
  .volumeF {
    font-size: 10px !important;
  }
}
@media only screen and (min-width: 300px) and (max-width: 700px) {
  .oddsBoxFancy {
    min-width: 40px;
    min-height: 35px;
  }
  .oddsBox {
    min-width: 40px;
    min-height: 25px;
  }
  .odds {
    font-size: 10px;
  }
  .volume {
    font-size: 6px;
  }
  .oddsBoxF {
    min-width: 30px;
    min-height: 20px;
  }
  .oddsF {
    font-size: 7px;
  }
  .volumeF {
    font-size: 5px;
  }
  .oddsFavHeight {
    min-width: 35px;
    min-height: 30px;
    padding: 1px 0px 1px 0px !important;
    font-size: 8px !important;
  }
  .odds4Height {
    min-height: 15px !important;
    min-width: 25px !important;
  }
  .bookHeight {
    min-height: 20px !important;

    .volumeSmallFont {
      font-size: 4px !important;
    }
    .odds {
      font-size: 6px !important;
    }
  }
}
</style>
