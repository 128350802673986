import axios from "axios";
/* eslint-disable */
const apiUrl = process.env.VUE_APP_ENV_365_API_URL;
const apiUrl2 = process.env.VUE_APP_ENV_1SPORTS_API_URL;
export default {
  getLiveGamesLight(data) {
    return axios.post(`${apiUrl2}/game/searchLiveGamesLight`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getLiveStreamDetails(id) {
    return axios.get(`${apiUrl2}/game/getOneMatch/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.accessToken,
      },
    });
  },
  getUpcomingGamesLight(data) {
    return axios.post(`${apiUrl}/game/searchUpcomingGamesLight`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  authenticateSubscribedUser() {
    return axios.get(`${apiUrl2}/game/authenticateSubscribedUser`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.accessToken,
      },
    });
  },
  getTypeOfGames(data) {
    return axios.post(`${apiUrl}/scheduleList/typesOfGames`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getTypeOfLiveGames(data) {
    return axios.post(`${apiUrl}/game/typesOfLiveGames`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  postUserGameLogs(data) {
    return axios.post(`${apiUrl2}/GameLogs`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.accessToken,
      },
    });
  },
};
