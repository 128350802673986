<template>
  <div class="d-flex">
    <v-select
      @change="changeLanguage()"
      v-model="lang"
      :items="locales"
      item-text="lang"
      item-value="code"
      class="languages"
    ></v-select>
  </div>
</template>
<script>
import userService from "@/services/userService";
export default {
  name: "LocaleSwitcher",
  data() {
    return {
      locales: [
        {
          code: "en",
          lang: "English (Englsih)",
        },
        {
          code: "hi",
          lang: "Hindi (हिन्दी)",
        },
        {
          code: "gu",
          lang: "Gujurati (ગુજરાતી)",
        },
        {
          code: "te",
          lang: "Telugu (తెలుగు)",
        },
        {
          code: "ta",
          lang: "Tamil (தமிழ்)",
        },
        {
          code: "kn",
          lang: "Kannada (ಕನ್ನಡ)",
          regionalLang: "(ಕನ್ನಡ)",
        },
      ],
      lang: this.$store.state.language.language,
    };
  },
  methods: {
    async changeLanguage() {
      this.$store.commit("language/setLanguage", this.lang);
      if (this.$store.state.user.isLoggedIn) {
        try {
          const data = {
            language: this.$store.state.language.language,
          };
          const result = await userService.updateUserLanguage(data);
          if (result.status == 200) {
            this.$store.commit("snackbar/setSnackbar", {
              content: this.$t("Language updated succesfully"),
              color: "success",
              icon: "success",
              isVisible: true,
            });
          }
        } catch (e) {
          console.log(e);
          this.$store.commit("snackbar/setSnackbar", {
            content: "Something Went Wrong",
            color: "error",
            icon: "error",
            isVisible: true,
          });
        }
      }
      this.$router.go();
    },
  },
};
</script>
<style scoped>
.languages {
  margin: 0 !important;
  padding: 0 !important;
}
</style>
<style>
.languages > .v-input__control > .v-text-field__details {
  display: none !important;
}

</style>