<template>
  <div
    :class="$vuetify.breakpoint.smAndDown ? '' : 'base-bg px-8 py-4'"
    class="section-wrap"
  >
    <Header title="Install app" />
    <h3 style="margin: 80px 0 0 0" class="text-center">
      {{ $t("How to install app on iphone/ios safari browser?") }}
    </h3>
    <ul class="mt-3">
      <li>
        {{ $t("Visit the website on safari browser") }}
      </li>
      <li>
        {{ $t("Tap the Share button (at the browser options)") }}
      </li>
      <div
        class="d-flex flex-column justify-space-between align-center"
        style="margin-left: -24px"
      >
        <v-img
          :aspect-ratio="16 / 9"
          width="90vw"
          max-width="600px"
          src="@/assets/images/ios-share.png"
          class="my-5"
        />
      </div>
      <li>
        {{
          $t(
            "From the options tap the Add to Homescreen option, you can notice an icon of the website or screenshot of website added to your devices homescreen instantly. Tap the icon from homescreen, then the app will be loaded"
          )
        }}
      </li>
      <div
        class="d-flex flex-column justify-space-between align-center"
        style="margin-left: -24px"
      >
        <v-img
          :aspect-ratio="16 / 9"
          width="90vw"
          max-width="600px"
          src="@/assets/images/ios-install.png"
          class="my-5"
        />
      </div>
    </ul>
    <div class="bottom"></div>
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
export default {
  components: { Header, Footer },
};
</script>

<style lang="scss" scoped></style>
