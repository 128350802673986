<template>
  <div class="fscreenhide">
    <div v-if="loading" class="d-flex justify-center">
      <circularLoader />
    </div>
    <div v-if="!playstarted && !favorites" class="d-flex justify-center">
      <flashLoader />
    </div>
    <div style="width: 100%; position: relative" :class="{ httt: !favorites }">
      <ads-banner v-if="banners?.length > 0" :images="banners" />
      <video
        width="100%"
        height="100%"
        :id="'playerDiv' + index"
        style="position: relative; background: black !important"
        :class="{
          favorite1: favorites && count == 1,
          favorite2: favorites && count == 2,
          favorite3: favorites && count == 3,
          favorite4: favorites && count == 4,
        }"
        disablePictureInPicture
        autoplay
        :controls="playstarted"
        :muted="favorites ? true : false"
        playsinline
      ></video>
      <button class="iphoneToolBar" @click="controlAudioForIphone()" v-if="isIos">
        <v-icon v-if="muted">mdi-volume-mute</v-icon>
        <v-icon v-if="!muted">mdi-volume-high</v-icon>
      </button>
      <div v-if="displaycommentaryText" class="commentaryText">
        {{ commentaryText }}
      </div>
      <marquee v-if="messageText" class="messageText">
        {{ messageText }}
      </marquee>
    </div>
  </div>
</template>

<script>
import circularLoader from "../components/circularLoader.vue";
import flashLoader from "../components/flashLoader.vue";
import { WebRTCAdaptor } from "@antmedia/webrtc_adaptor";
import * as SocketioService from "@/services/socketioService.js";
import router from "../router/index";
import gameService from "@/services/gameService";
import AdsBanner from "./AdsBanner.vue";
const ballStart = require("@/assets/sounds/ballStart.mp3");
const zero = require("@/assets/sounds/zero.mp3");
const one = require("@/assets/sounds/one.mp3");
const two = require("@/assets/sounds/two.mp3");
const three = require("@/assets/sounds/three.mp3");
const four = require("@/assets/sounds/four.mp3");
const six = require("@/assets/sounds/six.mp3");
const wicket = require("@/assets/sounds/wicket.mp3");
const noball = require("@/assets/sounds/noball.mp3");
const wide = require("@/assets/sounds/wide.mp3");
const freehit = require("@/assets/sounds/freehit.mp3");
const playersAreComing = require("@/assets/sounds/playersAreComing.mp3");
const heavyRain = require("@/assets/sounds/heavyRain.mp3");
const rain = require("@/assets/sounds/rain.mp3");
const coversAreComing = require("@/assets/sounds/coversAreComing.mp3");
const batsmanInjured = require("@/assets/sounds/batsmanInjured.mp3");
// const bowlerInjured = require("@/assets/sounds/bowlerInjured.mp3");
const appeal = require("@/assets/sounds/appeal.mp3");
const thirdUmpire = require("@/assets/sounds/thirdUmpire.mp3");
const notOut = require("@/assets/sounds/notout.mp3");
const inTheAir = require("@/assets/sounds/ballInTheAir.mp3");
const bowlerStopped = require("@/assets/sounds/bowlerStopped.mp3");
const checking = require("@/assets/sounds/checking.mp3");
const fastBowler = require("@/assets/sounds/fastBowler.mp3");
const spinBowler = require("@/assets/sounds/spinner.mp3");
const breakSound = require("@/assets/sounds/break.mp3");
const review = require("@/assets/sounds/review.mp3");
const overthrow = require("@/assets/sounds/overthrow.mp3");
// const catchDrop = require("@/assets/sounds/catchDrop.mp3");
const commenatry = require("@/assets/sounds/commentary.mp3");
export default {
  components: { circularLoader, flashLoader, AdsBanner },
  props: ["index", "streams", "favorites", "count", "id"],
  data() {
    return {
      loading: false,
      commentaryText: "",
      messageText: this.streams.message,
      displaycommentaryText: false,
      webRTCAdaptor: undefined,
      audio: null,
      mute: false,
      playstarted: false,
      inter: undefined,
      inter2: undefined,
      isIos: false,
      muted: false,
      gameId: undefined,
      banners: this.streams.banners,
    };
  },
  async mounted() {
    this.loading = true;
    this.gameId = this.$route.params.id;
    this.checkIos();
    this.startPlay();
    await this.joinSockets();
    await this.commentaryService();
    await this.logOutGame();
    await this.gameLogsJoin();
    await this.gameUpdateService();
    this.handlemultipleVolumeInFavorites();
    this.socketReconnection();
    if (document.getElementById("playerDiv" + this.index)) {
      document
        .querySelector("#playerDiv" + this.index)
        .addEventListener("click", (e) => e.preventDefault());
    }
  },
  methods: {
    async gameLogsJoin() {
      try {
        await gameService.postUserGameLogs({
          gameId: this.favorites ? this.id : this.$route.params.id,
          event: "join",
        });
      } catch (error) {
        console.log(error);
      }
    },
    async joinSockets() {
      if (this.favorites) {
        await SocketioService.socket.joinRooms({
          roomNames: ["commentaryRoom-" + this.id, this.id],
        });
      } else {
        await SocketioService.socket.joinRooms({
          roomNames: ["commentaryRoom-" + this.$route.params.id, this.$route.params.id],
        });
      }
    },
    async logOutGame() {
      SocketioService.eventBus.$on("logOut", async (data) => {
        router.push("/");
      });
    },
    async gameLogsLeft() {
      try {
        await gameService.postUserGameLogs({
          gameId: this.favorites ? this.id : this.gameId,
          event: "left",
        });
      } catch (error) {
        console.log(error);
      }
    },
    async commentaryService() {
      SocketioService.eventBus.$on("commentary", async (data) => {
        if ((!this.favorites && data.id == this.$route.params.id) || data.id == this.id) {
          if (data.text && data.com !== "Clear") {
            this.displaycommentaryText = true;
            this.commentaryText = data.com;
          } else {
            this.commentaryText = "";
            this.displaycommentaryText = false;
          }
          if (data.sound && !document.getElementById("playerDiv" + this.index).muted) {
            if (this.audio) {
              this.audio.pause();
            }
            if (data.com == "0 Runs") this.audio = new Audio(zero);
            else if (data.com == "1 Run") this.audio = new Audio(one);
            else if (data.com == "2 Runs") this.audio = new Audio(two);
            else if (data.com == "3 Runs") this.audio = new Audio(three);
            else if (data.com == "4 Runs") this.audio = new Audio(four);
            else if (data.com == "6 Runs") this.audio = new Audio(six);
            else if (data.com == "Wicket") this.audio = new Audio(wicket);
            else if (data.com == "No Ball") this.audio = new Audio(noball);
            else if (data.com == "Wide") this.audio = new Audio(wide);
            else if (data.com == "Free Hit") this.audio = new Audio(freehit);
            else if (data.com == "Ball Start") this.audio = new Audio(ballStart);
            else if (data.com == "Players Are Coming")
              this.audio = new Audio(playersAreComing);
            else if (data.com == "Heavy Rain") this.audio = new Audio(heavyRain);
            else if (data.com == "Rain") this.audio = new Audio(rain);
            else if (data.com == "Covers Are Coming")
              this.audio = new Audio(coversAreComing);
            else if (data.com == "Batsman Injured")
              this.audio = new Audio(batsmanInjured);
            // else if (data.com == "Bowler Injured")
            //   this.audio = new Audio(bowlerInjured);
            else if (data.com == "Appeal") this.audio = new Audio(appeal);
            else if (data.com == "Third Umpire") this.audio = new Audio(thirdUmpire);
            else if (data.com == "Not Out") this.audio = new Audio(notOut);
            else if (data.com == "In The Air") this.audio = new Audio(inTheAir);
            else if (data.com == "Bowler Stop") this.audio = new Audio(bowlerStopped);
            else if (data.com == "Checking") this.audio = new Audio(checking);
            else if (data.com == "Fast Bowler") this.audio = new Audio(fastBowler);
            else if (data.com == "Spin Bowler") this.audio = new Audio(spinBowler);
            else if (data.com == "Break") this.audio = new Audio(breakSound);
            else if (data.com == "Review") this.audio = new Audio(review);
            else if (data.com == "Overthrow") this.audio = new Audio(overthrow);
            // else if (data.com == "Catch Drop")
            //   this.audio = new Audio(catchDrop);
            else if (data.com == "Commentary") this.audio = new Audio(commenatry);
            else if (data.com == "Clear") this.audio = new Audio("");
            else {
              return;
            }
            if (
              data.com !== "Clear" &&
              data.com !== "Sorry" &&
              data.com !== "Good Night" &&
              data.com !== "Match Delayed" &&
              data.com !== "Wet Outfield"
            )
              this.audio.play();
          }
        }
      });
    },
    async gameUpdateService() {
      SocketioService.eventBus.$on("updateGameData", async (data) => {
        if ((!this.favorites && data.id == this.$route.params.id) || data.id == this.id) {
          if (data.message) {
            this.messageText = data.message;
          }
          if (data.message == "") {
            this.messageText = "";
          }
        }
      });
      SocketioService.eventBus.$on("updateBanners", async (data) => {
        if ((!this.favorites && data.id == this.$route.params.id) || data.id == this.id) {
          if (data.banners) {
            this.banners = data.banners;
          }
          if (data.banners.length == 0) {
            this.banners = [];
          }
        }
      });
    },
    socketReconnection() {
      SocketioService.eventBus.$on("reconnected", async () => {
        console.log("reconnected");
        this.joinSockets();
      });
    },
    startPlay() {
      let websocketURL = process.env.VUE_APP_ENV_AMS;
      let mediaConstraints = {
        video: false,
        audio: false,
      };
      let pc_config = {
        iceServers: [
          {
            urls: "turn:3.109.131.98:3478",
            username: "wohlig",
            credential: "wohlig",
          },
        ],
      };

      let sdpConstraints = {
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: true,
      };

      this.webRTCAdaptor = new WebRTCAdaptor({
        websocket_url: websocketURL,
        mediaConstraints: mediaConstraints,
        peerconnection_config: pc_config,
        sdp_constraints: sdpConstraints,
        remoteVideoId: "playerDiv" + this.index,
        isPlayMode: true,
        callback: (info, obj) => {
          if (info == "play_started") {
            var video = document.getElementById("playerDiv" + this.index);
            this.inter2 = setInterval(() => {
              if (video.readyState === 4) {
                this.playstarted = true;
                clearInterval(this.inter2);
              } else {
              }
            }, 100);
            console.log("play started");
          } else if (info == "play_finished") {
            console.log("play finished");
          } else {
            console.log(info + " notification received");
          }
        },
        callbackError: function (error, message) {
          console.log("error callback: " + JSON.stringify(error));
        },
      });
      this.inter = setInterval(() => {
        if (!this.playstarted)
          this.webRTCAdaptor.play(
            String(this.streams.streamId),
            this.streams.token,
            "",
            [],
            "",
            ""
          );
        if (this.playstarted) {
          clearInterval(this.inter);
        }
      }, 1000);
      this.loading = false;
    },
    checkIos() {
      if (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      ) {
        this.isIos = true;
      }
    },
    handlemultipleVolumeInFavorites() {
      if (this.favorites) {
        const vid = document.getElementById("playerDiv" + this.index);
        vid.onvolumechange = () => {
          if (vid.muted == false) {
            if (this.isIos) {
              this.muted = false;
            }
            for (let i = 0; i < this.count; i++) {
              try {
                if (
                  i !== this.index &&
                  document.getElementById("playerDiv" + i) &&
                  !document.getElementById("playerDiv" + i).muted
                ) {
                  document.getElementById("playerDiv" + i).muted = true;
                }
                if (
                  i !== this.index &&
                  document.getElementById("videoControls" + i) &&
                  !document.getElementById("videoControls" + i).muted
                ) {
                  document.getElementById("videoControls" + i).muted = true;
                }
                SocketioService.eventBus.$emit("muteLiveTvAudio", this.index);
              } catch (error) {
                console.log(error);
              }
              try {
                if (
                  i !== this.index &&
                  document.getElementById("h5live-playerDiv" + i) &&
                  !document.getElementById("h5live-playerDiv" + i).muted
                ) {
                  document.getElementById("h5live-playerDiv" + i).muted = true;
                }
              } catch (error) {
                console.log(error);
              }
            }
          } else {
            if (this.isIos) {
              this.muted = true;
            }
          }
        };
      } else {
        if (this.isIos) {
          const vid = document.getElementById("playerDiv" + this.index);
          vid.onvolumechange = () => {
            if (vid.muted == false) {
              this.muted = false;
            } else {
              this.muted = true;
            }
          };
        }
      }
    },
    async leaveSockets() {
      if (this.favorites) {
        await SocketioService.socket.leaveRooms({
          roomNames: ["commentaryRoom-" + this.id, this.id],
        });
      } else {
        await SocketioService.socket.leaveRooms({
          roomNames: ["commentaryRoom-" + this.$route.params.id, this.$route.params.id],
        });
      }
    },
    controlAudioForIphone() {
      document.getElementById("playerDiv" + this.index).muted = !document.getElementById(
        "playerDiv" + this.index
      ).muted;
    },
  },
  beforeDestroy() {
    this.gameLogsLeft()
      .then(() => {
        if (this.inter) {
          clearInterval(this.inter);
        }
        if (this.inter2) {
          clearInterval(this.inter2);
        }
        this.leaveSockets();
        this.webRTCAdaptor.stop(this.streams.streamId);
      })
      .catch((error) => {
        console.error("An error occurred during cleanup:", error);
      });
  },
};
</script>
<style scoped>
.fscreenhide video::-webkit-media-controls-fullscreen-button {
  display: none;
}
video::-webkit-media-controls-timeline {
  display: none !important;
}
video {
  object-fit: contain;
}
video::-webkit-media-controls-current-time-display {
  display: none;
}
.httt {
  height: 100vh;
}
.iphoneToolBar {
  position: absolute;
  bottom: 50px;
  right: 10px;
  z-index: 999;
  background: rgba(0, 0, 0, 0.52);
  display: inline;
}
</style>
