<template>
  <div>
    <div v-if="loading">
      <sectionLoader />
    </div>
    <div v-else>
      <div v-if="liveGames.length == 0 && !loading" class="text-center">
        <img src="@/assets/images/pana.svg" aspect-ratio="1.5" class="my-5" />
        <p class="font-16">
          {{ $t("Unfortunately, No live games found, Please try again later") }}
        </p>
      </div>
      <div v-else class="d-flex flex-column">
        <v-chip-group
          v-model="type"
          column
          mandatory
          class="mt-2 p-4 d-flex justify-start"
        >
          <v-chip v-for="tag in tags" :key="tag" filter active-class="text-red">
            {{ tag }}
          </v-chip>
        </v-chip-group>
        <v-row no-gutters align="stretch">
          <v-col
            :cols="$vuetify.breakpoint.xs && liveGames.length > 0 ? 12 : 6"
            :md="liveGames.length > 4 ? 4 : 4"
            v-for="(i, index) in liveGames"
            :key="index"
            style="padding: 0px"
            class="mb-3 minht"
          >
            <MatchDetailsCard :gamesData="i" @onEdit="reRender" />
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
import gameService from "../../services/gameService.js";
import MatchDetailsCard from "@/components/MatchDetailsCard.vue";
import sectionLoader from "@/components/sectionLoader.vue";
import * as SocketioService from "../../services/socketioService.js";
export default {
  data() {
    return {
      liveGames: [],
      loading: false,
      tags: ["All"],
      type: 1,
    };
  },
  components: {
    MatchDetailsCard,
    sectionLoader,
  },
  created() {
    this.getTypeOfLiveGames();
    SocketioService.eventBus.$on("update-games", () => {
      this.reRender();
    });
  },
  methods: {
    async getTypeOfLiveGames() {
      this.loading = true;
      this.tags = ["All"];
      try {
        const data = {
          on1Sports: true,
        };
        const result = await gameService.getTypeOfLiveGames(data);
        if (result.status == 200) {
          var others;
          var entertainment;
          result.data.forEach((element) => {
            if (element === "Others") {
              others = true;
            } else if (element === "Entertainment") {
              entertainment = true;
            } else {
              this.tags.push(element);
            }
          });
          if (entertainment) {
            this.tags.push("Entertainment");
          }
          if (others) {
            this.tags.push("Others");
          }
          this.getLiveGamesLight();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getLiveGamesLight() {
      try {
        const data = {
          user: this.$store.state.user.userId,
          type: this.type == 0 ? "" : this.tags[this.type],
        };
        const result = await gameService.getLiveGamesLight(data);
        if (result.status == 200) {
          this.liveGames = result.data.data.data;
          this.$emit("countLive", result.data.data.totalcount);
        }
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    reRender() {
      this.liveGames = [];
      this.getTypeOfLiveGames();
    },
  },
  watch: {
    type: {
      async handler() {
        this.getLiveGamesLight();
      },
    },
  },
};
</script>
//
<style lang="scss">
// .marginn {
//   margin-top: 20px;
//   margin-bottom: 20px;
// }
//

.v-tabs-bar__content {
  background-color: #2c2a2b !important;
}
.v-slide-group__content {
  justify-content: left !important;
  display: flex;
  align-items: center;
  padding: 0;
}
.v-slide-group__wrapper {
  .v-slide-group__content {
    span {
      margin: 1rem !important;
    }
  }
}
</style>
<style>
.minht {
  min-height: 130px;
}
@media only screen and (max-width: 500px) {
  .minht {
    min-height: 165px;
  }
}
@media only screen and (max-width: 768px) {
  .v-slide-group__wrapper > .v-slide-group__content > span {
    margin: 0.5rem !important;
  }
}

@media only screen and (max-width: 350px) {
  .v-slide-group__wrapper .v-slide-group__content span {
    margin: 0.5rem !important;
  }
}
</style>
