<template>
  <div class="card-wrap" style="position: relative; height: 100%">
    <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 12 : 2"
        :class="{ 'on-hover': hover }"
        class="my-1 cardbg"
        tile
        @click="!upcoming ? playStream() : null"
      >
        <v-btn
          class="favorite"
          fab
          x-small
          light
          :color="!gamesData.favorite ? 'transparent' : 'white'"
          @click.stop.prevent="editFavorite()"
          v-if="!upcoming"
        >
          <v-icon color="#7FC7D9">{{
            !gamesData.favorite ? "mdi-check-outline" : "mdi-check-bold"
          }}</v-icon>
        </v-btn>
        <div class="d-flex flex-column justify-space-between" style="height: 100%">
          <div style="height: 100%" class="d-flex flex-column justify-center">
            <!-- <div class="d-flex align-center py-2">
              <img
                src="@/assets/images/video-icon.png"
                class="img-fluid ml-3"
                style="max-height: 50px"
              />
              <v-icon x-large color="#7FC7D9"> mdi-play-circle </v-icon>
              <div
                class="d-flex justify-space-between align-center ml-3"
                style="width: 100%"
              >
                <div
                  class="d-flex flex-column justify-space-between "
                  style="width: 100%; background: transparent"
                >
                  <div class="font-16 font-size-title" style="width: 100%">
                    <strong>{{ gamesData.name }}</strong>
                  </div>
                  <div>
                    <div class="font-12 font-size-desc">
                      <strong>{{ gamesData.description }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="game-type">
              <div class="game-type-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="62"
                  height="62"
                  viewBox="0 0 62 62"
                  fill="none"
                >
                  <path
                    d="M29 1.51154e-05L62 33L62 61.5L29 28.5L0.499892 7.25094e-06L29 1.51154e-05Z"
                    fill="#3B99D1"
                  />
                </svg>
                <span class="game-type-text">{{ gamesData.type }}</span>
              </div>
            </div>
            <h3 class="card-description">
              {{ gamesData.description }}
            </h3>
            <div>
              <div>
                <h4 class="team-title">
                  {{ gamesData.name }}
                </h4>
              </div>
              <!-- <div class="vs-text">vs</div>
              <div>
                <h4 class="team-title">
                  {{ gamesData.name.split(" v ")[1] }}
                </h4>
              </div> -->
            </div>
          </div>
          <!-- <div v-if="!upcoming && !hover" class="text-center gold-bg font-14">
            <strong style="color: #533201">{{ gamesData.type }}</strong>
          </div>
          <div
            v-if="!upcoming && hover"
            class="text-center white-bg font-14 black-txt"
          >
            <strong>Click To Watch Live Now</strong>
          </div> -->
          <div class="text-center gold-btn rounded font-16" v-if="upcoming">
            <span> <v-icon color="white"> mdi-clock </v-icon></span>
            <span style="color: white" class="ml-1">{{
              gamesData.startTime | formatDateTime
            }}</span>
          </div>
        </div>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
import favoriteService from "@/services/favoriteService";
import NoSleep from "nosleep.js";
import gameService from "@/services/gameService";
export default {
  data() {
    return {
      zIndex: 100,
      nosleep: null,
    };
  },
  props: ["gamesData", "upcoming"],
  methods: {
    async editFavorite() {
      const data = {
        gameId: this.gamesData._id,
        userId: this.$store.state.user.userId,
        status: !this.gamesData.favorite ? "enabled" : "disabled",
      };
      try {
        const result = await favoriteService.editFavorite(data);
        if (result.status == 200) {
          if (result.data == this.$t("You can't add more than 4 favorites")) {
            this.$store.commit("snackbar/setSnackbar", {
              content: result.data,
              color: "error",
              isVisible: true,
            });
          } else {
            this.$emit("onEdit");
            this.$store.commit("snackbar/setSnackbar", {
              content:
                result.data.status == "enabled"
                  ? this.$t("Added to Favorite")
                  : this.$t("Removed from Favorite"),
              color: "success",
              isVisible: true,
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async playStream() {
      if (this.$nosleep) this.$nosleep.disable();
      this.$nosleep = new NoSleep();
      this.$nosleep.enable();
      try {
        const result = await gameService.authenticateSubscribedUser();
        if (result.status == 200 && result.data.isVerified == true) {
          this.$router.push({ path: `/livestream/${this.gamesData._id}` });
        }
      } catch (error) {
        console.log(error);
        if (error.response.status == 404 && error.response.data == "Not Subscribed") {
          this.$store.commit("snackbar/setSnackbar", {
            content: this.$t("Account Expired"),
            color: "error ",
            isVisible: true,
          });
        } else if (
          error.response.status == 404 &&
          error.response.data == "User Blocked"
        ) {
          this.$store.commit("snackbar/setSnackbar", {
            content: "Your account is blocked kindly contact admin",
            color: "error ",
            isVisible: true,
          });
        } else {
          this.$store.commit("snackbar/setSnackbar", {
            content: this.$t("Something Went Wrong"),
            color: "error ",
            isVisible: true,
          });
        }
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  transition: opacity 0.2s ease-in-out;
  border: 1px solid white !important;
}

.v-card:not(.on-hover) {
  opacity: 0.9;
  border: 1px solid #7fc7d9 !important;
}
.card-wrap {
}
.cardbg {
  height: 100%;
  background-size: 100% 100%;
  margin-left: 3px;
  margin-right: 3px;
  // width: 50%;
  padding: 1rem;
  margin-left: auto;
  margin-right: auto;

  border-radius: 10px !important;
  border: 1px solid #9a9a9a;
  background: linear-gradient(
    99deg,
    rgba(255, 255, 255, 0.2) 0.39%,
    rgba(59, 153, 209, 0.2) 94.11%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: blur(0px);
  margin: 1rem;
}
.play {
  border-radius: 10px;
  padding: 1.5rem 3rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 0.75rem;
}
.font-size-desc {
  color: #7fc7d9;
}
.font-size-title {
  color: white;
}
.favorite {
  position: absolute;
  bottom: 10px;
  right: 10px;
  border: 1px solid #7fc7d9 !important;
}
@media only screen and (max-width: 500px) {
  .font-size-title {
    font-size: 0.9375rem !important;
  }
  .font-size-desc {
    font-size: 0.75rem !important;
  }
}
@media only screen and (max-width: 400px) {
  .font-size-title {
    font-size: 0.92rem !important;
  }
  .font-size-desc {
    font-size: 0.7rem !important;
  }
}
</style>
<style>
.bg2 {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  opacity: 1;
  transition: 0.3s;
}
.c-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 70%;
  max-width: 300px;
  height: 246px;
  padding: 12px;
  background-color: white;
  border: 5px solid gold;
  border-radius: 5px;
  z-index: 10001;
  opacity: 1;
  transition: 0.6s;
  color: black;
}
.ptop {
  padding-top: 50px;
}
.card-description {
  color: #fa0;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}
.team-title {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.v-overlay__scrim {
  opacity: 0 !important;
}
.vs-text {
  font-size: 0.75rem;
  margin-left: 1rem;
}
.game-type {
  position: absolute;
  top: 0;
  right: 0;
}
.game-type-svg {
  position: relative;
}
.game-type-text {
  position: absolute;
  transform: rotate(45deg);
  left: 10px;
  top: 18px;
  width: 100%;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
@media only screen and (max-width: 320px) {
  .c-modal {
    width: 90%;
  }
  .logo {
    width: 93.27px;
  }
}
</style>
