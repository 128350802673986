import axios from "axios";
/* eslint-disable */
const apiUrl = process.env.VUE_APP_ENV_1SPORTS_API_URL;

export default {
  getDownloadLink() {
    return axios.get(`${apiUrl}/apkVersion/getLatestVersion`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
};
